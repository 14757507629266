import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Button, Stack } from '@mui/material';
import { DeckGL } from '@deck.gl/react';
import { BitmapLayer, PolygonLayer } from '@deck.gl/layers';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import { OrthographicView, OrthographicController } from '@deck.gl/core';

export const UI_Grid_Item_Img_Layer_DeckGL_3_CoordSys_Bitmap = ({ imageUrl, annotations = [] }) => {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [viewState, setViewState] = useState(null);
  
  // Initialize visibleLayers with all annotation labels set to true (visible)
  const [visibleLayers, setVisibleLayers] = useState(() => {
    const layers = {};
    if (annotations && annotations.length) {
      annotations.forEach(a => {
        if (a.label) layers[a.label] = true;
      });
    }
    return layers;
  });

  // Set up resize observer for container
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setContainerSize({ width, height });
      }
    });
    if (containerRef.current) observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  // Load image and get dimensions
  useEffect(() => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      setImgDimensions({ width: img.width, height: img.height });
    };
    img.src = imageUrl;
  }, [imageUrl]);

  // Update view state when container or image dimensions change
  useEffect(() => {
    if (containerSize.width > 0 && containerSize.height > 0 && imgDimensions.width > 0 && imgDimensions.height > 0) {
      // Calculate zoom to fit image properly in container
      const containerRatio = containerSize.width / containerSize.height;
      const imageRatio = imgDimensions.width / imgDimensions.height;
      
      let zoom;
      if (containerRatio > imageRatio) {
        // Container is wider than image (relative to height)
        zoom = Math.log2(containerSize.height / imgDimensions.height);
      } else {
        // Container is taller than image (relative to width)
        zoom = Math.log2(containerSize.width / imgDimensions.width);
      }
      
      setViewState({
        target: [0, 0, 0], // Center of the image
        // zoom: zoom - 1, // Adjust zoom level to fit
        zoom: zoom, // Adjust zoom level to fit
        minZoom: zoom - 3,
        maxZoom: zoom + 3,
        pitch: 0,
        bearing: 0
      });
    }
  }, [containerSize, imgDimensions]);

  if (!viewState) {
    return (
      <Grid container style={{ height: '100%', border: '3px solid purple' }}>
        <Grid item xs={6} ys={12}>
          <Box ref={containerRef} sx={{ position: 'relative', height: '100%', border: '3px solid violet' }}>
            <div>Loading image and calculating dimensions...</div>
          </Box>
        </Grid>
        <Grid item xs={6} ys={12} sx={{ padding: '1em', border: '3px solid aqua' }}>
        </Grid>
      </Grid>
    );
  }

  // Define bounds around center point
  const bounds = [
    [-imgDimensions.width / 2, -imgDimensions.height / 2],
    [imgDimensions.width / 2, -imgDimensions.height / 2],
    [imgDimensions.width / 2, imgDimensions.height / 2],
    [-imgDimensions.width / 2, imgDimensions.height / 2]
  ];

  // Create bitmap layer with proper bounds
  const bitmapLayer = new BitmapLayer({
    id: 'bitmap-layer',
    bounds,
    image: imageUrl,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN
  });

  // Filter annotations based on visible layers
  const visibleAnnotations = annotations.filter(a => visibleLayers[a.label]);

  // Create polygon layer only with visible annotations
  const polygonLayer = visibleAnnotations.length > 0 ? new PolygonLayer({
    id: 'polygon-layer',
    data: visibleAnnotations,
    getPolygon: (d) => {
      // Transform polygon coordinates to match the centered image coordinate system
      return d.polygon.map(point => [
        point[0] - imgDimensions.width / 2,
        imgDimensions.height / 2 - point[1]  // Flip Y coordinate
      ]);
    },
    getFillColor: (d) => d.color || [255, 0, 0, 100],
    getLineColor: [0, 0, 0],
    lineWidthMinPixels: 1,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    stroked: true,
    filled: true,
    pickable: true,
    onHover: ({ object }) => {
      if (object) console.log('Hovered:', object.label);
    }
  }) : null;

  return (
    <Grid container style={{ height: '100%', border: '3px solid purple' }}>
      <Grid item xs={6} ys={12}>
        <Box
          ref={containerRef}
          sx={{
            position: 'relative',
            height: '100%',
            border: '3px solid violet',
          }}
        >
          <DeckGL
            views={new OrthographicView({
              flipY: false
            })}
            controller={{type: OrthographicController}}
            viewState={viewState}
            onViewStateChange={({viewState}) => setViewState(viewState)}
            layers={[bitmapLayer, ...(polygonLayer ? [polygonLayer] : [])]}
          />
        </Box>
      </Grid>
      <Grid item xs={6} ys={12}
        sx={{
          padding:'1em',
          border: '3px solid aqua',
        }}
      >
        <Stack spacing={2}>
          {Array.from(new Set(annotations.map((a) => a.label))).map((label) => (
            <Button
              key={label}
              variant={visibleLayers[label] ? 'contained' : 'outlined'}
              onClick={() =>
                setVisibleLayers((prev) => ({
                  ...prev,
                  [label]: !prev[label],
                }))
              }
            >
              {visibleLayers[label] ? 'Hide' : 'Show'} {label}
            </Button>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};