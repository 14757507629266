import {useState, useEffect} from 'react'

import {Grid, Box, Paper, Card, CardMedia, TextField, IconButton, Button} from '@mui/material'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';




export const UI_Grid_Item_Place_Form = () =>{

  const [img_address, set_img_address] = useState(null)

  const [img_list, set_img_list] = useState([])

  const handleImageUpload = (event) => {

  };


  const [form_data_dict, set_form_data_dict] = useState({});

  const handleChange = (event) => {
    set_form_data_dict({ ...form_data_dict, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Form Data:", { ...form_data_dict,});

  }


  const image_upload = (event) =>{
  
    const file_image = event.target.files?.[0];

    // ---- OPT 1 ----
    // if (file_image){
    //   const reader = new FileReader()
    //   reader.onload = (event) =>{
    //     set_img_address(event.target.result)
    //   }
    //   reader.readAsDataURL(file_image)
    // }

    // ---- OPT 2 ----
    if (file_image){
      const url_image = URL.createObjectURL(file_image)
      set_img_address(url_image)
      // set_img_list(img_list.push(url_image))
      set_img_list([...img_list, url_image])
    }

    // ---- OPT 3 ----, could save image first, but images might be erroneous, so no need to save until submit
    // set_img_address('http://www.orbnode.com:8000/sub-api/IMG_2483')
  }

  // useEffect(()=>{
  //   set_img_address('http://www.orbnode.com:8000/sub-api/IMG_2483')
  // }, [])


    const handleUpload = async () => {
      
      if (!form_data_dict.place_name?.trim() || !form_data_dict.place_address?.trim()) {
        return; // Exit the function if either field is empty or only contains spaces
      }
      
      const formData = new FormData();

      formData.append('place_name', form_data_dict.place_name)
      formData.append('place_address', form_data_dict.place_address)

      // const response = await fetch("http://localhost:8000/datasets/add", {
      const response = await fetch("http://orbnode.com:8000/datasets/add", {
        method: "POST",

        body: formData,
      })
      .then(response => response.text())
      .then(data => {
      console.log("[ORBNODE MSG] " + data);
      })
      // .then(fetchImageList())
      .catch(error => {
      console.error('Error uploading image:', error);
      });

        
      set_form_data_dict({"place_name": "", 'place_address':''});
      set_img_address(null);
    }
  return(


    <Grid container
    alignItems="center" // Centers items vertically
    justifyContent="center" // Centers items horizontally

    sx={{
      height: "100%",
      padding: "2em",
      // border:'solid salmon'
    }}


    >

      <Grid item
          xs={12}
          sm={8}
          md={5}
          lg={5}
          xl={3}
          >




      <Card

        sx={{mx: "auto", p: 3, display: "flex", flexDirection: "column", gap: 2, borderRadius: 6,
        // sx={{ maxWidth: '30%', mx: "auto", p: 3, display: "flex", flexDirection: "column", gap: 2,
        // alignItems:"center", // Centers items vertically
        // justifyContent:"center" // Centers items horizontally
      
      }}
      >
      {/* <Card sx={{ mx: "auto", p: 3, display: "flex", flexDirection: "column", gap: 2 }}> */}


      <Box
      sx={{
        // border:'solid purple'
      }}
      >
        <span
        style={{fontSize:'2em',
          // border: 'solid pink'
        }}
        >
          Add a new place</span>
        </Box>

      <Box
      sx={{
        textAlign:'left',
        // border:'solid purple'
      }}
      >
        {/* exits & entrances, interior & exterior  */}
        <span style={{margin:'0px'}}>Add images of storefront, signage, doorways, spaces</span>
      </Box>


      {



        <Box
        sx={{
          // border:'solid purple',

          display: "flex",
          flexDirection: "row",
          // justifyContent: "center",
          // alignItems: "center",

        }}
        >



          <input
          id='image-upload'
          type='file'
          accept="image/*"
          onChange={image_upload}

          style={{ display: 'none' }}

          />




          <label
          htmlFor='image-upload'
          
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          >


                        
          <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            border: 'solid rgba(0,0,0,0.2) .05em',
            borderRadius:6,
            // paddingTop:'1em',
            cursor: "pointer",
            
            // "&:hover": { bgcolor: "action.hover" }
            "&:hover": { bgcolor: "rgba(0,24,45,0.1)" },
            // "&:hover": { border: "dashed grey" },
          
            height:'6em',
            width:'6em'
          }}
          >
            <AddAPhotoIcon/>
            {/* <img height="40px" src='https://huggingface.co/front/assets/huggingface_logo-noborder.svg'/> */}
          </Box>



        </label>

 {/* Render uploaded images */}
 {img_list.map((img_address, idx) => (
              <Paper
                key={idx}
                sx={{
                  display: "flex",
                  borderRadius: 6,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "6em",
                  width: "6em",
                  overflow: "hidden",
                }}
              >
                <img
                  src={img_address}
                  alt="Uploaded"
                  style={{
                    width: "100%",
                    imageRendering: "auto",
                  }}
                />
              </Paper>
            ))}

        {img_address?
        (


          <Paper
          sx={{
            display: "flex",
            borderRadius:6,

            justifyContent: "center",
            alignItems: "center",
            // border: "dashed black 0.2em",
            height:'6em',
            width:'6em',
            overflow: "hidden",
          }}
        >
              <img
                src={img_address}
                alt="Uploaded"
                style={{
                  // height and width will cause resize and image squash issue
                  width: "100%",
                  // height: "100%",
                  // objectFit causes render to look pixelated
                  // objectFit: "cover", // Ensures proper fit
                  imageRendering: "auto",
                }}
              />
          </Paper>
          // <img src='http://www.orbnode.com:8000/sub-api/PXL_20240418_181519812.MP'/>
        )
        :
        (<></>)
        }




        </Box>


      }


      <TextField
        label='Place name'
        name="place_name"
        value={form_data_dict.place_name}
        onChange={handleChange}
        // fullWidth


        // textfield labels are high index and above other elements
        // Use styling rule definition in top of component, and/or use theme colors for standard colors and/or zindex
        sx={{
          zIndex:0,
        }}
      />

      <TextField
        label='Place address'
        name="place_address"
        value={form_data_dict.place_address}
        onChange={handleChange}
        // fullWidth

        
        // textfield labels are high index and above other elements
        // Use styling rule definition in top of component, and/or use theme colors for standard colors and/or zindex
        sx={{
          zIndex:0,
        }}
      />




      {/* TODO place coords by map choice */}
      {/* <TextField
        label="Place lat"
      />

      <TextField
        label="Place lon"
      /> */}


      {/* <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}> */}
      <Button
      variant="contained"
      color="primary"
      onClick={handleUpload}
      >
      Submit
      </Button>


        </Card>



    


</Grid>

</Grid>
    
  )
}