import React, { useRef, useEffect, useState } from 'react';
import { DeckGL } from '@deck.gl/react';
import { PolygonLayer } from '@deck.gl/layers';
import { Box, Grid, Button, Stack } from '@mui/material';

export const UI_Grid_Item_Img_Layer_DeckGL_1 = ({ imageUrl, annotations }) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });
  const [visibleLayers, setVisibleLayers] = useState({});

  const pixel_padding = 3;

  // Get original image dimensions and container scaling
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      let scale = containerWidth / img.width;
      let scaleHeight = img.height * scale;

      if (scaleHeight > containerHeight) {
        scale = containerHeight / img.height;
      }

      const scaleWidth = img.width * scale;
      const scaleHeightFinal = img.height * scale;

      setDimensions({
        width: scaleWidth-6,
        height: scaleHeightFinal-6,
        imageWidth: img.width,
        imageHeight: img.height,
        containerWidth,
        containerHeight,
        scale
      });
    };
    img.src = imageUrl;
  }, [imageUrl]);

  // Normalize coordinates based on scale
  const scaledAnnotations = annotations.map((ann) => {
    if (!visibleLayers[ann.label]) return null;

    const scaleX = dimensions.width / dimensions.imageWidth;
    const scaleY = dimensions.height / dimensions.imageHeight;

    const scaledPolygon = ann.polygon.map(([x, y]) => [x * scaleX, y * scaleY]);

    return new PolygonLayer({
      id: `polygon-${ann.label}`,
      data: [{ polygon: scaledPolygon }],
      getPolygon: (d) => d.polygon,
      getFillColor: ann.color || [255, 0, 0, 100],
      getLineColor: [0, 0, 0],
      lineWidthMinPixels: 1,
      stroked: true,
      filled: true,
      pickable: true,
    });
  }).filter(Boolean);

  // Offset container border width in pixels
  const offsetX = dimensions.containerWidth
    ? ((dimensions.containerWidth - dimensions.width) -6)/ 2
    : 0;
  const offsetY = dimensions.containerHeight
    ? ((dimensions.containerHeight - dimensions.height)-6) / 2
    : 0;

  return (
    <Grid container style={{ height: '100%',
      border: '3px solid purple',
    }}>
      <Grid item xs={6} ys={12}>
        <Box
          ref={containerRef}
          sx={{
            position: 'relative',
            // width: '100%',
            height: '100%',
            border: '3px solid violet',
          }}
        >
          <img
            src={imageUrl}
            alt="background"
            style={{
              width: dimensions.width,
              height: dimensions.height,
              position: 'absolute',
              top: offsetY,
              left: offsetX,
            }}
          />
          <DeckGL
            initialViewState={{
              longitude: 0,
              latitude: 0,
              zoom: 0,
              bearing: 0,
              pitch: 0,
            }}
            controller={false}
            width={dimensions.width}
            height={dimensions.height}
            style={{
              position: 'absolute',
              top: offsetY,
              left: offsetX,
              pointerEvents: 'none',
            }}
            layers={scaledAnnotations}
          />
        </Box>
      </Grid>
      <Grid item xs={6} ys={12}
      sx={{
        // height: '50%',
        padding:'1em',
        border: '3px solid aqua',

      }}
      >
        <Stack spacing={2}>
          {Array.from(new Set(annotations.map((a) => a.label))).map((label) => (
            <Button
              key={label}
              variant={visibleLayers[label] ? 'contained' : 'outlined'}
              onClick={() =>
                setVisibleLayers((prev) => ({
                  ...prev,
                  [label]: !prev[label],
                }))
              }
            >
              {visibleLayers[label] ? 'Hide' : 'Show'} {label}
            </Button>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};