// import {useState} from 'react';
import React, { useState, useEffect } from 'react';

import {Box, Grid, Paper} from '@mui/material'

import {UI_Grid_Item_Chat} from './UI_Grid_Item_Chat'
import {UI_Grid_Item_Dummy} from './UI_Grid_Item_Dummy'
import {UI_Grid_Item_Dummy_Geo} from './UI_Grid_Item_Dummy_Geo'
import {Message} from './UI_Grid_Item_Chat_Response'
import {UI_Grid_Item_Map} from './UI_Grid_Item_Map'

import {UI_Grid_Item_ToolSelect} from './UI_Grid_Item_ToolSelect';

import MapComponent from './data_map'

import {UI_Grid_Item_Cards} from './UI_Grid_Item_Cards'

export const UI_Grid_View_Trie = () => {

  const [value, setValue] = useState('')
  const [response, setResponse] = useState('')

  const numbers = [0,1,2,3,4,5,6,7,8,9,10]

  const [imageList, setImageList] = useState(null);

  const [valueGeo, setValueGeo] = useState('')
  const [responseGeo, setResponseGeo] = useState('')

  const fetchImageList = () => {  
    // fetch('http://'+address_backend+'/data/image-list-return/', {

    fetch('http://www.orbnode.com:8000/data/image-list-return/',{
    // fetch('http://3.90.25.43:8000/data/image-list-return/',{
    // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-list-return/', {
    // fetch('http://172.18.0.2:8000/data/image-list-return/',{
        method: 'GET',
  })
      .then(response => response.json())
      .then(data => {
        setImageList(data.images);
        // setShowMap(true);
        console.log('test:'+toString(data.images))
      })
      .catch(error => {
        console.error('Error fetching image list:', error);
        // setShowMap(false);
      });
  };




  useEffect(() => {
    fetchImageList();
  }, []);



    return(
        <Grid container
        sx={{
            // Needs to be fixed or map, etc wont render underneath
            position: 'fixed',

            zIndex: 1000, // Make sure it's on top of other elements
            
            pointerEvents:  'none',
            '@media (pointer: coarse)': {
              pointerEvents: 'none', // Enable pointer events on touch devices
            },

            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'flex-end',

            // overflowY: 'auto', // Scroll upwards when overflow happens
            // height: '100vh',
            height: '100svh', // Use Safari-compatible viewport unit

          }}
        >




          <Grid item
          xs={12} sm={1.5} md={1.5} lg={2.5} xl={4}
          // xs={12} sm={4} md={4} lg={2.5} xl={3}
          sx={{

            overflowY: 'auto', // Scroll upwards when overflow happens

            display: 'flex',
            flexDirection: 'row', // Stack content from the bottom to the top
            justifyContent: 'flex-start', // Align items at the start after reversing the order
            
            border: 'solid purple 5px',
            height: '100vh',
            // padding: '8px',

            display: { xs: 'none', sm: 'block'},

            WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on Safari
          

          }}
          >

            {/* <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy>
            <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy>
            <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy> */}


            {/* <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                border: '1px solid gray',
                height: '150px',
                padding: '8px',
              }}
              >
            <p>Content for Item 2</p>
            </Paper> */}


            <UI_Grid_Item_Cards/>
            
          </Grid>


          <Grid item
          xs={12} sm={9} md={9} lg={7} xl={4}
          // xs={12} sm={4} md={4} lg={7} xl={6}

          // justifyContent="center" alignItems="center"

          sx={{
            pointerEvents: 'none',

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',

              // overflowY: 'auto', // Scroll upwards when overflow happens

              // height: '100vh',
              height: '100svh',

              // padding: '1vh',
              padding: '.25em',
              paddingBottom: '1em',
              // margin will cause overflow vs. padding if using lg, etc  = {3} to have size lg ={x} across
              // ex. 3 across with lg={4} might overflow to only 2 across with margin
              // margin: '.25em',

              
              // border: 'solid indigo 5px',
              WebkitOverflowScrolling: 'touch', // Smooth scrolling on Safari


              '@media (max-width: 90px)': {
                height: '100dvh', // Alternative for devices with dynamic toolbars
              },

            }}
          >

            {/* <MapComponent></MapComponent> */}
            {/* {imageList && imageList.length > 0 && <UI_Grid_Item_Map imageList={imageList}/>} */}
            
            {/* {responseGeo && */}
            {/* {imageList && */}
            {imageList && imageList.length > 0 &&
              <UI_Grid_Item_Dummy_Geo imageList={imageList} value={value} responseGeo={responseGeo} ></UI_Grid_Item_Dummy_Geo>
            }


            {/* IMAGE LIST VERSION OF DUMMY CONTAINER ORIG FOR MAP USE */}
            {response&&
            // {imageList && imageList.length > 0 &&
            <UI_Grid_Item_Dummy imageList={imageList} value={value} response={response}></UI_Grid_Item_Dummy>
            }

            {/* IMAGE LIST VERSION OF DUMMY CONTAINER ORIG FOR MAP USE */}
            {/* {response&&
            <UI_Grid_Item_Dummy value={value} setValue={setValue} response={response} setResponse={setResponse}></UI_Grid_Item_Dummy>
            } */}
            
            
            {/* <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy> */}


            {/* <Message isUser='false' text='hello'></Message>
            <Message text='hello'></Message> */}

            <UI_Grid_Item_Chat
            value={value}
            setValue={setValue}
            response={response}
            setResponse={setResponse}
            responseGeo={responseGeo}
            setResponseGeo={setResponseGeo}
            >
            </UI_Grid_Item_Chat>


            {/* <UI_Grid_Item_Chat></UI_Grid_Item_Chat> */}

            {/* <UI_Grid_Item_ToolSelect></UI_Grid_Item_ToolSelect> */}


          </Grid>




          <Grid item
          // xs={12} sm={4} md={4} lg={2.5} xl={3}

          sx={{
            pointerEvents:  'auto',

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',

              // overflowY: 'auto', // Scroll upwards when overflow happens
              // height: '100vh',

              
              display: { xs: 'none', sm: 'block' },

              // border: 'solid red 5px',

              WebkitOverflowScrolling: 'touch', // Smooth scrolling on Safari

            }}
          >

            {/* <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy>
            <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy>
            <UI_Grid_Item_Dummy></UI_Grid_Item_Dummy> */}



          </Grid>


          
{/* 
        

        
        <Grid
        xs={11} sm={9} md={9} lg={4}

        sx={{
            // position: 'fixed',
            // bottom: 20,

            pointerEvents: 'none',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',

            overflowY: 'auto', // Scroll upwards when overflow happens
            height: '100vh',
          }}
        >
          {
            numbers.map((num, idx) => (
                <Box
                sx={{
                    height: '100vh',
                    border: 'solid black'
                }}>
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    Text
                    </Box>
                )
            )
          }
        </Grid> */}


        </Grid>
    )
}