// Message.js
import React from 'react';
import { Avatar, Grid, Typography, Paper, Box} from '@mui/material';

export const Message = ({ isUser, text, avatarSrc }) => {

        const CodeBlock = ({ code }) => {
        return (
          <Paper
            elevation={1}
            sx={{
              backgroundColor: '#f5f5f5',
              padding: 2,
              borderRadius: 1,
              whiteSpace: 'pre-wrap',
              maxWidth: '100%',
            }}
          >
            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
              {code}
            </Typography>
          </Paper>
        );
      };

      const renderMessage = (message) => {
        const codeBlockRegex = /```(.*?)```/gs;
        const parts = message.split(codeBlockRegex);
    
        return parts.map((part, index) => {
          if (index % 2 === 0) {
            // Normal text
            return <Typography key={index} variant="body1">{part}</Typography>;
          } else {
            // Code block
            return <CodeBlock key={index} code={part} />;
          }
        });
      };

  return (
    <>
      {!isUser && (
        <>

        <Grid container
        //   display="flex"
        //   justifyContent={isUser ? 'flex-end' : 'flex-start'}
        //   alignItems="flex-start"
    
          mb={0}
          
          sx={{
            // flexDirection: 'column-reverse', /* Reverses the display order */
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-end',
            
            // border:'solid red'
          }}
        >

          <Grid item
            lg={1}
            >
            <Avatar
            alt="Chat Model"
            src={avatarSrc}
            //   sx={{ mr: 1 }}
            />
          </Grid>

          <Grid item
          lg={11}
          >
            <Paper
            elevation={1}
            sx={{
            p: 2,
            //   maxWidth: '60vh',
            //   maxWidth: '80%',
            backgroundColor: isUser ? '#1976d2' : '#e0e0e0',
            color: isUser ? '#fff' : '#000',
            borderRadius: 4,

            textAlign: 'left'

            }}
            >

                {/* <Typography variant="body2">{text}</Typography> */}
                {/* <Typography variant="body2">{renderMessage(text)}</Typography> */}
                {renderMessage(text)}

            </Paper>
          </Grid>


      </Grid>
      </>
      )}

      {isUser && (
        <>

        <Grid container
        //   display="flex"
        //   justifyContent={isUser ? 'flex-end' : 'flex-start'}
        //   alignItems="flex-start"
    
        // MARGIN interacts with UI_Grid_Item_ChatResponse & Padding in UI_Grid_Item_Chat_System
        //   m={2}
          mb={1}

          sx={{
            // flexDirection: 'column', /* Reverses the display order */
            flexDirection: 'row-reverse', /* Reverses the display order */
            // display: 'flex',
            // justifyContent: 'flex-end',
            
            // border:'solid green'
          }}
        >


        <Grid item
            lg={1}
            >
            <Avatar
            alt="User"
            src={avatarSrc}
            //   sx={{ ml: 1 }}
            />
          </Grid>


          <Grid item
          lg={11}
          >
            <Paper
            elevation={1}
            sx={{
            p: 2,
            //   maxWidth: '60vh',
            //   maxWidth: '80%',
            backgroundColor: isUser ? '#1976d2' : '#e0e0e0',
            color: isUser ? '#fff' : '#000',
            borderRadius: 4,

            textAlign: 'left'
            }}
            >

                {/* <Typography variant="body2">{text}</Typography> */}
                {/* <Typography variant="body2">{renderMessage(text)}</Typography> */}
                {renderMessage(text)}

            </Paper>
          </Grid>


        </Grid>
        

        </>

      )}

</>
  );
};