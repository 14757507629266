import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Stage, Layer, Image as KonvaImage, Line, Text } from 'react-konva';
import useImage from 'use-image';

const PolygonLayer = ({ points, visible, color, label, x, y}) => (
  <>
    {/* <Line points={points} closed fill={color} opacity={0.5} visible={visible} /> */}
    <Line
          points={points}
          x={x}
          y={y}

          closed={true} // Closes the shape
          // opacity={0.5} // Set opacity of the stroke
          opacity={0.5} // Set opacity of the stroke
          visible={visible} //true, or false bool

          // fill={color} // can be different than stroke, inner line color
          // fill={'purple'} // can be different than stroke, inner line color

          stroke={color} //can be different than fill, outer line color
          // stroke={'red'} //can be different than fill, outer line color
          strokeWidth={4} // Line thickness
          // fill={null} // No fill, making it transparent, default, will be null if no prop is assigned
        />

    {visible && label && (
      <Text
      x={x + points[0]}  // first point's absolute X
      y={y + points[1]}  // first point's absolute Y
      // x,y are top left coordinate adjusted to scale to konva canvas container from component calling this konva component
      // x={x}
      // y={y}

        text={label}
        fontSize={16}
        fill="red"
        fontStyle="bold"
        // offsetY={-20}
      />
    )}
  </>
);

export const UI_Grid_Item_Img_Konva_1 = ({ imageUrl, polygons, width, height }) => {
  const containerRef = useRef(null);
  const [image] = useImage(imageUrl);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [visibleLayers, setVisibleLayers] = useState(polygons.map(() => true));

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setContainerSize({ width: clientWidth, height: clientHeight });
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (image) {
      setImageSize({ width: image.width, height: image.height });
    }
  }, [image]);

  const toggleLayer = (index) => {
    setVisibleLayers((prev) => prev.map((v, i) => (i === index ? !v : v)));
  };

  const scale =
    imageSize.width && imageSize.height
      ? Math.min(containerSize.width / 2 / imageSize.width, containerSize.height / imageSize.height)
      : 1;

  const scaledPolygons = polygons.map((poly) => ({
    ...poly,
    points: poly.points.map((p, i) => (i % 2 === 0 ? p * scale : p * scale)),
  }));

  const pixel_padding = 5;

  return (
    // error,if useEffect returns imageSize before it is referenced within the outer box of the image & overlay controls
    //width:`${imageSize.width}px`, height:`${imageSize.height}px`,
    // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'row',width:`${imageSize.width}px`, height:`${imageSize.height}px`,border: '2px solid green'}}>

    // without buttons, height is needed, if not height already as attribute within outer box for image and image overlay buttons
    // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'row', width:'100%', height: '100%', border: '3px solid green'}}>

    // semantic ui error, column and 100% of middle box this component is contained within, column buttons are occluded
    // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column', width:'100%', height: '100%', border: 'solid green'}}>
    // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column', width:'100%', border: 'solid green'}}>
    <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'row', width:'100%', height: '50%', border: '3px solid green'}}>
    {/* // <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'row', width:'100%', border: '2px solid green'}}> */}
    
    {/* must be height, width from box to get containerSize for scaling + padding, or else, x,y etc assignment will fail without active program error */}


    {/* Canvas Side */}
      <Box sx={{
        width: '100%',
        // height: '100%',
        border: '2px solid white'}}>
        {containerSize.width > 0 && containerSize.height > 0 && image && (
          <Stage width={containerSize.width / 2 - pixel_padding} height={containerSize.height}>
            <Layer>
              <KonvaImage
              // pixel_padding from above outer box
                x={(containerSize.width / 2 - pixel_padding - imageSize.width * scale) / 2}
                y={(containerSize.height - imageSize.height * scale) / 2 - pixel_padding}
                image={image}
                scaleX={scale}
                scaleY={scale}
                />
              {scaledPolygons.map((poly, index) => (
                <PolygonLayer
                  key={index}
                  points={poly.points}
                  color={poly.color}
                  label={poly.label}
                  visible={visibleLayers[index]}
                  x={(containerSize.width / 2 - pixel_padding - imageSize.width * scale) / 2}
                  y={(containerSize.height - imageSize.height * scale) / 2}
                  />
              ))}
            </Layer>
          </Stage>
        )}
      </Box>

      {/* Controls Side */}
      <Box sx={{
              // overflowY: 'auto', // Scroll upwards when overflow happens
              width: '100%',
              // height: '100%',
              p: 2 }}>
        <Stack spacing={2}>
          {polygons.map((_, index) => (
            <Button
              key={index}
              variant={visibleLayers[index] ? 'contained' : 'outlined'}
              onClick={() => toggleLayer(index)}
            >
              {visibleLayers[index] ? `Hide` : `Show`} Layer {index + 1}
            </Button>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};