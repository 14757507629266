import React from 'react';
import {MapContainer, TileLayer, Marker, Popup, ZoomControl, GeoJSON, WMSTileLayer} from 'react-leaflet';


import L from 'leaflet';
// import arrowImage from '../images/arrow.svg'
import 'leaflet-rotatedmarker';
import {useState, useEffect} from 'react';

import {ImageOverlay} from "react-leaflet";

// import GeoTIFFOverlay from './data_geotiff'

// import './styles.css'
// import {MUICard} from './data_card'


// import img_in from '/datasets/orbnode/database_images/PXL_20240413_180753312.MP.jpg'

// Function to fetch magnetic declination data from NOAA API
async function fetchMagneticDeclination(latitude, longitude) {
  const url = `https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=${latitude}&lon1=${longitude}&resultFormat=json`;
  
  try {
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.Declination) {
          return parseFloat(data.Declination);
      } else {
          throw new Error("Declination not found in response");
      }
  } catch (error) {
      console.error("Error fetching magnetic declination:", error);
      return null;
  }
}


const MapComponent = ({ imageList }) => {

  const [magDir, setMagDir] = useState(0);

  // const [url, setUrl] = useState('');
  const filename = 'geotiff_test.tiff'
  // const url_in  = `http://localhost:8000/files/${filename}`
  // const url_in  = require('./Washington_DC.tif')
  // const url_in  = 'https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/styles/full_width/public/media/images/Landsat9_20230409_WashingtonDC_B432.jpg?itok=4C5AEMy8'
  // const url_in  = require('./PXL_20240413_180753312.MP.jpg')
  // const url_in  = require('../../../../../datasets/orbnode/database_images/PXL_20240413_180753312.MP.jpg')
  // const url_in  = require("../../../../../datasets/orbnode/database_images/geotiff_test.tiff")

  // const url_in_jpg2000 = require('./geotiff_test_aerial.png')
  // const url_in_jpg2000 = require('./632.png')
  // const url_in_jpg2000 = require('./DCOCTO-2023.jp2')


  const bounds = [
    [38.98680201653501, -77.13808562151067],
    [38.83743269570888, -76.94730993325301]
  ];
  
  // Adjust icon paths for Leaflet to work with React
  delete L.Icon.Default.prototype._getIconUrl;
  
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
    iconUrl: require("leaflet/dist/images/marker-icon.png").default,
    shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
  });


  // useEffect(() => {
  //   // Simulating fetching a URL
  //   const fetchUrl = async () => {
  //     try {
  //       // Assuming the URL fetch from an API
  //       const response = await fetch('http://localhost:8000/files/geotiff_test.tiff');
  //       const data = await response.json();
  //       setUrl(data.url); // Make sure your API returns an object with a url property
  //       console.log('url:'+url)
  //     } catch (error) {
  //       console.error('Failed to fetch URL:', error);
  //     }
  //   };

  //   fetchUrl();
  // }, []); // Empty dependency array means this effect will only run once after the initial render



  //geoserver
  
  // const [geojsonData, setGeojsonData] = useState(null);

  // useEffect(() => {
  //   // Fetch GeoJSON data from GeoServer
  //   fetch("http://localhost:8080/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=	orbnode_name:Washington_DC_Boundary&outputFormat=application/json")
  //     .then(response => response.json())
  //     .then(data => setGeojsonData(data));
  // }, []);

  // console.log('#23532523532532')
  // console.log(geojsonData)
  // console.log('#23532523532532')






  if (imageList.length <= 0){
    return (<></>)
  }
  const [lat, lon] = [imageList[0].metadata['GPS GPSLatitude'], -1*imageList[0].metadata['GPS GPSLongitude']]



  // var markerIcon = new L.divIcon({
  //   // html: `<div style="transform: rotate(${direction}deg);">&#10148;</div>`,
  //   html: `<img src="${arrowImage}" style="transform: rotate(${0}deg); width: ${40}px; height: ${40}px;" />`,
  //   // html: `<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDUGavpaVwUv8P_aNPXT4QoKYKt0DbpuvEDw&usqp=CAU" style="transform: rotate(${direction}deg); width: ${20}px; height: ${20}px;" />`,
  //   // html: `<img src="https://huggingface.co/front/assets/huggingface_logo-noborder.svg" style="transform: rotate(${direction}deg); width: ${64}px; height: ${64}px;" />`,
  //   // iconUrl: "https://huggingface.co/front/assets/huggingface_logo-noborder.svg",
  //   className: 'arrow-marker-icon',
  //   iconSize: [64, 64],
  //   iconAnchor: [64/2, 64/2],
  // });








  return (
<div
style={{
  pointerEvents:  'auto',
  }}>

{/*
no need for marginTop, so app can be single page with fixed nav bar top & search bar etc. bottom
<MapContainer center={[lat,lon]} zoom={18} style={{height:"100vh", marginTop: "1em"}}> */}
<MapContainer

center={[lat,lon]}
// center={[0,0]}

zoom={12}
// zoom={2}

maxZoom={19}

style={{
  // pointerEvents:  'auto',

  // height:"70vh",
  height:"100vh",
  // marginTop: "0em",
}}
// if zoom control is not false or declared, zoom control will render top left
zoomControl={false}
>



      <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      // url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.png"

      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      
      maxZoom={20}
      
      />


      {/* <TileLayer
              //titiler
              // url="http://localhost:2000/cog/tile/{z}/{x}/{y}.png?url=$/datasets/dc_ortho_2023_cog.tif`"
              // url="http://localhost:2000/cog/tile/{z}/{x}/{y}.png?url=./datasets/DC_COG.tif"
              // url="http://localhost:2000/cog/tiles/WGS1984Quad/{z}/{x}/{y}.png?url=https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/54/T/WN/2023/8/S2A_54TWN_20230815_0_L2A/TCI.tif"


              // url="http://localhost:2000/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?url=https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/54/T/WN/2023/8/S2A_54TWN_20230815_0_L2A/TCI.tif"
              // url="http://localhost:2000/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?url=./datasets/DC_COG.tif"
              // url="http://localhost:2000/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.png?url=http://localhost:8080/datasets/DC_COG.tif"
              // http://localhost:2000/cog/viewer

              //fastapi or httpx server
              // url="http://localhost:8000/tiles/{z}/{x}/{y}.png"
              //http://localhost:8000/datasets/dc_0_18_mercator/18/75008/161936.png
              // url="http://localhost:8000/dc_0_18_mercator/{z}/{x}/{y}.png"

              // url="./dc_0_18_mercator/{z}/{x}/{y}.png"


              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      /> */}


    {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}

    {/* <WMSTileLayer
        url="http://localhost:8080/geoserver/wms"
        layers="orbnode_name:dc_ortho_2023_cog"
        format="image/png"
        transparent={true}
        version="1.1.1"
        attribution="&copy; GeoServer"
      /> */}




    {/* {geojsonData && <GeoJSON data={geojsonData} />} */}




      {/* will turn zoom control back on in selected position */}
      {/* <ZoomControl position="bottomright" /> */}

      {/* bounds must still be used although not passed */}
      {/* <GeoTIFFOverlay url={url_in} /> */}

      {/* <ImageOverlay
        // url={url_in}
        url={url_in_jpg2000}
        bounds={bounds}
        opacity={.7}
      /> */}

      {imageList.map((image, index) => {

        // Image direction multiplier, similar to N-S, E-W for lat lon
        // var directionMultiplier = image.metadata['GPS GPSImgDirectionRef'] === 'M' ? 1 : -1;
        var directionMultiplier = image.metadata['GPS GPSImgDirectionRef'] === 'M' ? 1 : -1;

        if (image.metadata['GPS GPSImgDirection'][0].split('/').length > 1){
          var direction_numerator = image.metadata['GPS GPSImgDirection'][0].split('/')[0]
          var direction_denomenator = image.metadata['GPS GPSImgDirection'][0].split('/')[1]
          var direction_ratio = parseFloat(direction_numerator, 10)/parseFloat(direction_denomenator, 10)
          direction_ratio = direction_ratio
        }

        else if (image.metadata['GPS GPSImgDirection'][0].split('/').length == 1){
          var direction_numerator = image.metadata['GPS GPSImgDirection'][0].split('/')[0]
          var direction_ratio = parseFloat(direction_numerator, 10)
        }

        var direction = 0+ (direction_ratio * directionMultiplier)// + -90


        var orientation = image.metadata['Image Orientation']

        // Convert orientation to an angle in degrees
        let angle = 0;
        switch (orientation) {
          case 1:
            angle = 0;
            break;
          case 6:
            angle = 90;
            break;
          case 3:
            angle = 180;
            break;
          case 8:
            angle = 270;
            break;
          // Add cases for other orientations if necessary
        }


        // Example coordinates (replace with your own)
        const latitude = 40.7128; // Example latitude (e.g., New York City)
        const longitude = -74.0060; // Example longitude


        // Fetch magnetic declination for the given coordinates
        fetchMagneticDeclination(image.metadata['GPS GPSLatitude'], -1*image.metadata['GPS GPSLongitude'])
            .then(magneticDeclination => {
                if (magneticDeclination !== null) {
                    console.log("Magnetic declination:", magneticDeclination);
                    setMagDir(magneticDeclination)
                    // Now you can use the magnetic declination to adjust orientation information
                } else {
                    console.log("Failed to fetch magnetic declination");
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });


            angle = (angle + magDir)
            angle %= 360;
            if (angle < 0) {
                angle += 360;
            }

            
            direction = (direction + angle)%360

        var markerIcon = new L.divIcon({
          // html: `<div style="transform: rotate(${direction}deg);">&#10148;</div>`,
          // html: `<img src="${arrowImage}" style="transform: rotate(${direction}deg); width: ${40}px; height: ${40}px;" />`,
          // html: `<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDUGavpaVwUv8P_aNPXT4QoKYKt0DbpuvEDw&usqp=CAU" style="transform: rotate(${direction}deg); width: ${20}px; height: ${20}px;" />`,
          html: `<img src="https://huggingface.co/front/assets/huggingface_logo-noborder.svg" style="transform: rotate(${direction}deg); width: ${64}px; height: ${64}px;" />`,
          // iconUrl: "https://huggingface.co/front/assets/huggingface_logo-noborder.svg",
          className: 'arrow-marker-icon',
          iconSize: [64, 64],
          iconAnchor: [64/2, 64/2],
        });

        // var directionIcon = L.marker([36, 42], {
        //   icon: L.divIcon({
        //     className: 'arrow-marker-icon',
        //     html: `<img src="${arrowImage}" style="width: 32px; height: 32px;" />`,
        //     iconSize: [32, 32],
        //     iconAnchor: [16, 16],
        //   }),
        //   rotationAngle: direction, // set the rotation angle
        // });


          return (

          <Marker
            key={index}
            position={[image.metadata['GPS GPSLatitude'], -1*image.metadata['GPS GPSLongitude']]}
            icon={markerIcon}
            // icon={directionIcon}
            // rotationAngle={direction} // set the rotation angle
            >

            

            <div className='leaflet-react-popup'>


            <Popup
            sx={{
              leafletPopupScrolled: 'black',
              /* Add other styles here if needed */
            }}
            >
              
                {/* <MUICard
                // custom class added in css for popup background modification
                className="customPopup"
                image_ob={image} /> */}


                {/* <img src={require("../../../../../datasets/orbnode/database_images/"+image.file)} alt={image.file} style={{ maxWidth: '50px'}} />
                <p>{magDir}</p> */}

                {/* <p>{image.saved}</p>
                <p>{direction}</p>
                <p>{directionMultiplier}</p>

                <p>{image.metadata['GPS GPSImgDirection']}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection']}</p>
                <p>{image.metadata['GPS GPSImgDirection'].length}</p>

                <p>{image.metadata['GPS GPSImgDirection'][0]}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection'][0]}</p>

                <p>{image.metadata['GPS GPSImgDirection'][0].split('/')[0]}</p>
                <p>{image.metadata['GPS GPSImgDirection'][0].split('/')[1]}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection'][0].split('/')}</p> */}
            </Popup>


            </div>

          </Marker>
          )
      }
      )
      }




        {/* <Marker
            key={42}
            position={[38.90870833333333, -1*77.00253055555555]}
            icon={markerIcon}
            // icon={directionIcon}
            // rotationAngle={direction} // set the rotation angle
            >

            <Popup>
            <img src={require("https://huggingface.co/front/assets/huggingface_logo-noborder.svg")} alt={'image desc'} style={{ maxWidth: '50px'}} />

            </Popup>

          </Marker> */}
          
    </MapContainer>
  </div>
  )
}

export default MapComponent;
