import {Box, Grid, Card, CardContent, CardActions, CardMedia, Button, Typography, Paper, Chip} from '@mui/material'
import {Breadcrumbs, Link} from '@mui/material'

import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {UI_Grid_Item_Image_Slider} from './UI_Grid_Item_Image_Slider'
// import AudioPlayer from './data_audio'





// props is all props passed in and props.image_ob accesses the image_ob variable assigned the image item json object from the image list in the UI_Grid_Item_Cards component

export const UI_Grid_Item_Card_Poster = (props) =>{


const image_ob_in = props.image_ob

// const image_current = require("../../public/datasets/orbnode/database_images/"+image_ob_in.file)
// const image_current = require("../../../../../datasets/orbnode/database_images/"+image_ob_in.file)

// const image_current = `http://localhost:8000/sub-api/${encodeURIComponent(image_ob_in.file)}`;
const image_current = `http://www.orbnode.com:8000/sub-api/${encodeURIComponent(image_ob_in.file)}`;
// const image_current = `http://www.orbnode.com:8000/sub-api/${image_ob_in.file}`;


    return(

            <>

            <Card
            sx={{display: "flex", flexDirection: "column",borderRadius: 6, padding:0, 
            }}>






                
            {/* <p>{image_ob_in.file}</p>
            <p>{image_current}</p> */}

            <CardContent
                    sx={{height:'15em',
                        // border:'solid green',
                        padding:0
                    }}

            >


                {/* <Paper
                    elevation={1}
                    sx={{
                    // backgroundColor: '#f5f5f5',
                    borderRadius: 4,
                    }}


                // key={index}
                style={{
                    padding: '10px',
                    cursor: 'pointer',
                //   backgroundColor: hoverIndex === index ? 'lightgrey' : 'transparent',
                    border: '1px solid #ddd',
                    marginBottom: '0.5 vh',
                    borderRadius: '4px',
                }}

                //   onMouseEnter={() => setHoverIndex(index)} // Highlight on hover
                //   onMouseLeave={() => setHoverIndex(null)} // Remove highlight on hover out
                //   onClick={() => handleSelect(suggestion, index)} // Trigger function on click
                    >


                    {'alexdelapaz'}


                </Paper> */}




                {/* card component contain
                (1) card media (2) card content (3) card actions */}

                    {/* (1) card media */}
                    {/* add images to card with CardMedia */}
                    {/* <CardMedia
                    component='img'
                    // height='150px'
                    // only need height or width for img to size
                    image={image_current}
                    height='300em'
                    // no height it a smartphne like aspect

                    sx={{
                        borderRadius: '16px', // Adjust the radius as needed
                      }}                    >
                    </CardMedia> */}

                    
                    {/* Using container such as paper, allows for image contained box without pixelation */}
                    {/* <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // border: "dashed black 0.2em",
                            height:'15em',
                            overflow: "hidden",
                            margin:0,
                        }}
                    > */}


                    {/* <CardMedia
                    component="img"
                    image={image_current}
                    // explicitg sizing causes render to look pixelated
                    // srcSet={`${image_current}?w=300 300w, ${image_current}?w=600 600w`}
                    // sizes="(max-width: 600px) 300px, 600px"
                    // height="300em"
                    /> */}




                    {/* <UI_Grid_Item_Image_Slider images_in={[
                    // 'http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NDozMy4wNjE4NDcrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDE4XzE4MTUxOTgxMi5NUA%3D%3D',
                    'http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D',
                    'https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg',
                    'http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NDozMy4wNjE4NDcrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDE4XzE4MTUxOTgxMi5NUA%3D%3D',

                    // 'https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg'
                    ]}/> */}



                    <img
                        src={image_current}
                        alt="Uploaded"
                        style={{
                        objectFit: "cover", // Ensures no distortion, just cropping
                        width: "100%",
                        height: "100%",
                        // objectPosition: "center", // Keeps the center as the focal point
                        // aspectRatio: "1/1", // Forces the image to be a square

                        // objectFit causes render to look pixelated
                        // objectFit: "cover", // Ensures proper fit
                        // imageRendering: "auto",
                        }}
                    />


                    {/* <CardMedia
                    component="img"
                    image={image_current}
                    // srcSet={`${image_current}?w=300 300w, ${image_current}?w=600 600w`}
                    // sizes="(max-width: 600px) 300px, 600px"
                    height="200em"
                    /> */}


                    {/* </Box> */}



                    </CardContent>





                {/* (1) card content */}
                <CardContent
                sx={{
                    textAlign:'left',
                }}>
                    {/* <Typography
                    gutterBottom
                    variant='caption'
                    component='div'
                    >
                        Techno modular session
                    </Typography> */}


                    <Typography
                    variant='title'
                    // color='text.secondary'

                    sx={{
                        padding:'.5em',
                        display:'block'
                    }}

                    >
                        Coffee Shop Alpha
                    </Typography>







                    <Typography
                    variant='caption'
                    color='text.secondary'
                    
                    sx={{
                        padding:'.5em',
                        display:'block'
                    }}

                    >
                        Local coffee shop with excellent vibes. Serves specialty coffee and baked goods.
                        
                    </Typography>







                    <Grid container
                    sx={{
                        padding:'.5em',
                    }}
                    >

                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Cafes"
                            />
                        </Grid>

                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Coffee Shops"
                            />
                        </Grid>
                        
                        
                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Bakeries"
                            />
                        </Grid>
                        
                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Live music"
                            />
                        </Grid>
                        
                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Reading spots"
                            />
                        </Grid>
                        
                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Meet-ups"
                            />
                        </Grid>
                        
                        <Grid item
                                //   xs={12} sm={6} md={3} lg={3} xl={4}
                                  >
                            <Chip
                            variant='outlined'
                            size="small"
                            label="Working spots"
                            />
                        </Grid>
                        
                    </Grid>






                {/* (1) card content */}
                    {/* items after/before collapse */}
                    <Breadcrumbs
                    itemsAfterCollapse={1}
                    itemsBeforeCollapse={2}
                    maxItems={2}

                    aria-label='breadcrumb'
                    separator='/'
                    
                    sx={{
                        padding:'.5em',
                        margin:'0.25em',
                        // border:'solid rgba(200,200,200,.2) 0.1em',
                        borderRadius:10,

                    }}
                    >
                        {/* links nested within breadcrumb component
                        only text for current page is not created as link */}
                        <Link style={{fontSize:'0.6em'}} variant='p' color='secondary' underline='hover' href='#'>alexdelapaz</Link>
                        <Link style={{fontSize:'0.6em'}} color='primary' underline='hover' href='#'>skatespots</Link>
                    </Breadcrumbs>






                {/* (3) card actions */}
                {/* add buttons to Card */}
                {/* <CardActions>
                        <Button size='small'>
                            Add to events
                        </Button>

                        <Button size='small'>
                            Share
                        </Button>
                </CardActions> */}
                


                </CardContent>


            {/* <AudioPlayer/> */}
    
            </Card>
            </>

        )
    }