import App from '../App'
import React, { useState, useEffect } from 'react';

import {Box, Stack, Divider, Grid, InputAdornment, requirePropFactory} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MicIcon from '@mui/icons-material/Mic';
import {Autocomplete, TextField} from '@mui/material'
import MapIcon from '@mui/icons-material/Map';
import PublicIcon from '@mui/icons-material/Public';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

// import Recorder from 'react-voice-recorder';

// import {MUICard} from './data_card'
// import {MUICard} from './data_card_manual'
// import Config_Ingest from './Config_ingest'
// import logo from '../images/orbnode_logo.png'



import {Paper, Typography, Button} from '@mui/material';


export const UI_Grid_Inverse_1 = () => {

    const [imageList, setImageList] = useState([]);
    const address_backend = 'localhost:8000'
    // const address_backend = Config_Ingest()
    
    const fetchImageList = () => {

        fetch('http://'+address_backend+'/data/image-list-return/', {

        // fetch('http://localhost:8000/data/image-list-return/',{
        // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-list-return/', {
        // fetch('http://172.18.0.2:8000/data/image-list-return/',{
            method: 'GET',
    })
        .then(response => response.json())
        .then(data => {
            setImageList(data.images);
        })
        .catch(error => {
            console.error('Error fetching image list:', error);
        });
    };

  useEffect(() => {
    fetchImageList();
  }, []);


  console.log(imageList[0])




  const skills = ['HTML', 'CSS', 'JavaScript', 'TypeScript', 'React']


  

  //const [value, setValue] = useState(null)
  const [value, setValue] = useState('')

  const handleRight = () =>{
    setValue('wOw')
  }



  const [returnData, set_return_data] = useState(null)

  const imgSearch = () =>{

    // fetch('http://${address_backend}/ping', {
    fetch('http://'+address_backend+'/ping', {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data_in =>{
            set_return_data(data_in.requestor_ip)
        })
        .catch(error => {
            console.error('[ERROR] ping]', error);
        });
  }




  const [value_search_predict, set_value_search_predict] = useState(null)


  const orbnode_search_geo = () => {
                fetch(`http://${address_backend}/data/toolbar-search/`, {
                //fetch('http://'+address_backend+'/data/toolbar-search/', {
                // fetch('http://localhost:8000/data/toolbar-search/',{
                // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/toolbar-search/', {
                // fetch('http://172.18.0.2:8000/data/toolbar-search/',{
        
    
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ text: value })  // Send the current value
                })
                .then(response => response.text())
                .then(data_str => {
                    set_value_search_predict(data_str);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            };


    useEffect(() => {
        console.log("Effect run due to value change:", value);
        const handler = setTimeout(() => {
            if (value) {
                console.log("Calling API with value:", value);
                orbnode_search_geo();
            }
        }, 500);
    
        return () => {
            console.log("Clearing timeout");
            clearTimeout(handler);
        };
    }, [value]);
            



  console.log('---Test geo api')
//   console.log(JSON.stringify(value_search_predict))
  console.log(value_search_predict)
  console.log(typeof(value_search_predict))
  console.log('Test geo api---')






  const [components, setComponents] = useState([]);

  const addComponent = () => {
    setComponents((prevComponents) => [
      <Paper key={prevComponents.length} elevation={3} sx={{ padding: 2, mb: 2, width: '100%' }}>
        <Typography>This is component {prevComponents.length + 1}</Typography>
      </Paper>,
      ...prevComponents,
    ]);
  };




  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  }

    return(
        <Box
        sx={{
            position: 'fixed',
            // bottom: 20,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000, // Make sure it's on top of other elements
            pointerEvents: 'none',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',

            overflow: 'hidden',

            // with 100vh minheight can produce margin y from bottom of page for text box
            // and other grid items, boxes etc can have margin populating upwards
            minHeight: '100vh',
            // minHeight: '98vh',
            // paddingBottom: '2vh',

            //100vh min hieght will make border over by pixel size of border
            // border: 'solid purple 5px'//'solid purple 1vh'

          }}




        //   sx={{
        //     position: 'fixed',
        //     // bottom: 20,
        //     bottom: 0,
        //     left: 0,
        //     right: 0,

        //     // backgroundColor: 'transparent',
        //     // backdropFilter: "blur(1px)",
        //     // backgroundColor: 'rgba(255, 255, 255, .7)',

        //     // borderRadius: 50,
        //     // border: 'solid magenta',

        //     // padding: '1em',
        //     paddingBottom: '2em',
        //     // borderTop: '1px solid #ccc',
        //     zIndex: 1000, // Make sure it's on top of other elements

        //     // justifyContent:'center',
        //     // alignItems:'center',
        //     // width:'100%'
        // }}
        >

        {/* <> */}





        {/* <Grid container spacing={1}> */}
        <Grid container
        // my={2}
        >
        {/* use with padding & margin for google search bar like effect */}
        {/* <Grid container spacing={0}> */}


{/* 







        <Grid item
          xs={12} sm={12} md={12}
        //   sx={{border: 'solid purple .15em',
        //     borderRadius: 5,

        // }}
        sx={{
            pointerEvents: 'auto',
        }}
          >


        <>{components}</>

        <Typography>Chat Box</Typography>
        <Button onClick={addComponent} variant="contained">
        Add Component Above
        </Button>



          </Grid>
       







          <Grid container justifyContent="center" alignItems="center">

            <Grid item
                xs={11} sm={9} md={9} lg={3.5}
                sx={{border: 'solid purple .2em',
                borderRadius: 5,

                pointerEvents: 'auto',

                }}
            >
                
                <Button onClick={imgSearch} variant="contained">
                    SEARCH IMG
                    <br/>
                    SEARCH IMG

                </Button>

                <div>
                {returnData}
                </div>

                </Grid>
            </Grid> */}




            {!value_search_predict?<></>:
            <Grid container justifyContent="center" alignItems="center"
            my={.25}
            >

                <Grid
                                        xs={11} sm={9} md={9} lg={5}

                            sx={{
                                // border: 'solid purple .2em',
                                borderRadius: 4,
                                borderBottomLeftRadius: 0, 
                                borderBottomRightRadius: 0,
                                
                                overflow: 'hidden', // Prevents content from overflowing the border

                            }}
                            >
                    

                        <Grid item

                        sx={{
                            overflow: 'hidden', // Prevents content from overflowing the border


                            // backgroundColor: 'rgba(255,255,255,0.1)',
                            backgroundColor: 'white',
                            // padding:'600px',

                            // google search bar like effect
                            // paddingBottom:'20px',
                            // marginBottom:'-20px',


                            pointerEvents: 'auto',

                            // xs will be smaller with 5vh relative comatred to lg for desktop
                            // maxWidth: '5vh', // Set the maximum height for scrolling
                            // overflowX: 'auto', // Enable vertical scrolling
                            
                            maxHeight: '20vh', // Set the maximum height for scrolling
                            
                            overflowY: 'auto', // Enable vertical scrolling
                            textAlign: 'left',

                            // // control scroll
                            // boxSizing: 'border-box', // Ensures padding does not affect box size
                            // '&::-webkit-scrollbar': {
                            //   width: '8px',
                            // },
                            // '&::-webkit-scrollbar-thumb': {
                            //   backgroundColor: '#888', // Styling the scrollbar thumb
                            //   borderRadius: '4px',
                            // },
                            // '&::-webkit-scrollbar-track': {
                            //   backgroundColor: '#f1f1f1',
                            //   borderRadius: '4px',
                            // },

                            overflow: 'hidden', // Prevents content from overflowing the border

                            // pt: .5,

                          }}

                        >
                            
                        {/* <p>{value_search_predict}</p> */}
                        {value_search_predict.split('/n').map((line, idx)=>(

                            (
                                <Box
                                key={idx}
                                onClick={() => handleRowClick(idx)}
                                sx={{
                                  cursor: 'pointer',
                                  p: 1,
                                  backgroundColor: selectedRow === idx ? 'lightblue' : 'transparent',
                                  '&:hover': {
                                    backgroundColor: selectedRow === idx ? 'lightblue' : 'lightgrey',
                                  },
                                }}
                              >
                                    <span
                                    sx={{
                                        display:"inline",
                                    }}
                                    key={idx}
                                    >
                                        {line}
                                        <br/>
                                        {/* <br/> */}
                                    </span>
                                </Box>

                            )
                        )
                    )}

                        </Grid>
                        </Grid>

            </Grid>}



            <Grid container justifyContent="center" alignItems="center">

                <Grid item
                        xs={11} sm={9} md={9} lg={5}
                        my={.25}
                        sx={{
                            // margin bottom will override bottom margin produced from my={.25} above
                            marginBottom: '2vh',

                            // use margin top for language input textbox grid item component
                            // in combination with text reccomendation data output component above
                            // for seamless component with interactive suggestian input output functionality
                            marginTop: '0vh',

                            pointerEvents: 'auto',

                        }}
                        >

                {/* <MUICard image_ob={image} /> */}


                {/* <Autocomplete
                    options={skills}
                    renderInput={(params)=><TextField {...params} label='Skills'/>}
                    value={value}
                    onChange={(newValue)=>setValue(newValue)}
                    /> */}

                {/*                 
                <TextField
                color='secondary'
                size='medium'

                    label='search for something to do...'

                    multiline
                    style={{ width: '100%' }}

                    >

                    </TextField> */}


                <TextField
                // when page loads text field is the selected component where the cursor begins
                autoFocus 

                // color='secondary'
                size='small'
                // fullWidth

                // sx={{ background: 'transparent' }}
                sx={{
                    // Border radius outer for text field, match to .MuiInputBase-root borderRadius
                    // also the background of the text field
                    // transparent will also solve mismatches border radius
                    // borderRadius:2,
                    // borderRadius:4,
            
                    // using border radius and background color outside of '& .MuiInputBase-root'
                    // below will have double the effects of a backdrop and border

                    // backgroundColor: 'transparent', // negates borderRadius 2 because mismatch of 2 borderRadius are not seen
                    // backgroundColor: 'rgba(255,255,255,0.1)',
                    // backgroundColor: 'white',

                    '& .MuiInputBase-root':{
                        borderRadius:4,
                        borderTopLeftRadius: 0, 
                        borderTopRightRadius: 0, 
                        // boxShadow: '10',
                        // backgroundColor: 'rgba(0,0,0, 0.2)',
                        // backgroundColor: 'transparent'
                        backgroundColor: 'white',
                        padding: '1em',

                        // color: 'black', // Change the text color if needed
                    },

                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent', // Default border color
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent', // Border color when focused
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent', // Border color when hovered
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'black', // Label color
                        '&.Mui-focused': {
                          color: 'black', // Label color when focused
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: 'black', // Input text color
                      },


                    // '& .MuiInputLabel-root': {
                    //     color: 'transparent', // If you want to also make the label transparent
                    // },


                }}
 

                // sx={{
                //     position: 'fixed',
                //     bottom: 0,
                //     left: 0,
                //     right: 0,
                //     // backgroundColor: 'transparent',
                //     backdropFilter: "blur(3px)",
                //     borderRadius: 15,
                //     // border: 'solid magenta',
                //     // padding: '10px',
                //     // borderTop: '1px solid #ccc',
                //     zIndex: 1000, // Make sure it's on top of other elements
                //     }}
                
                // sx={{
                //     // Root class for the input field
                //     "& .MuiOutlinedInput-root": {
                //       color: "#000",
                //       fontFamily: "Arial",
                //       fontWeight: "bold",
                //       // Class for the border around the input field
                //       "& .MuiOutlinedInput-notchedOutline": {
                //         borderColor: "#2e2e2e",
                //         borderWidth: "2px",
                //       },
                //     },
                //     // Class for the label of the input field
                //     "& .MuiInputLabel-outlined": {
                //       color: "grey",
                //       fontWeight: "bold",
                //     },
                //   }}


                onInput={
                    (e)=>{
                        // const textarea = e.target;
                        // for typescript
                        const textarea = e.target;
                        textarea.style.height='auto';
                        // control based on text amount
                        // textarea.style.height = `${textarea.scrollHeight}px`;
                        // also limit to max size of 16 etc.
                        textarea.style.height = `${Math.min(textarea.scrollHeight, 10 * textarea.clientHeight)}px`;
                        
                        // scrollheight is the total text adding to the height
                        // clientheight is a predetermined height per textfield
                        console.log(textarea.scrollHeight)
                        console.log(textarea.clientHeight)
                        console.log(3 * textarea.clientHeight)
                    }
                }

                multiline
                rows={1}
                fullWidth 
                // label={'search for something to do...'}

                value={value}
                onChange={(i)=>{
                    setValue(i.target.value)
                    }
                }

                InputProps={{

                    startAdornment: (
                    <InputAdornment position="start">
                        {/* Add your search icon here
                        <svg>...</svg> */}

                        {/* <img src={logo} width={'40px'}/> */}
                        {/* <img src={'https://www.alexdelapaz.com/images/id.jpg'} width={'40px'}/> */}
                        {/* <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdKCgSY1O9svcENbY9rD0veMH87OZ9jNj7w&s'} width={'40px'}/> */}
                        {/* import logo from '../images/orbnode_logo.png' */}

                        <img src={require('../images/orbnode_logo.png')} width={'40em'}/>
                        
                        {/* <MapIcon/> */}
                        {/* <PublicIcon/> */}
                    </InputAdornment>
                    ),
                    // style: {fontSize: 40}

                    // endAdornment: (
                    //     <InputAdornment position="end">
                    //       <MapIcon
                    //         onClick={console.log('wOw')}
                    //       />
                    //     </InputAdornment>
                    //   )

                    // endAdornment: (
                    // <InputAdornment position="end">
                    //     <IconButton
                    //     aria-label="toggle password visibility"
                    //     onClick={handleRight}
                    //     >
                    //     {value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    //     </IconButton>
                    // </InputAdornment>
                    // )


                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <IconButton onClick={handleMicClick}>
                    //       <MicIcon />
                    //     </IconButton>
                    //   </InputAdornment>
                    // ),



                    endAdornment: (
                        <>

                        <InputAdornment position="end">
                            <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            onChange={handleRight}
                            capture="environment" // This attribute is to suggest devices to use camera
                            />
                            <label htmlFor="upload-file">
                            {/* <label> */}
                                <IconButton variant="outlined" color='primary' component="span">
                                
                                <AddAPhotoIcon/>
                                {/* <MicIcon/> */}

                                </IconButton>
                            </label>
                        </InputAdornment>

                        </>
                    ),

                    
                  }}

                
                />
                    </Grid>

            </Grid>

        {/* size 12 grid item container, within overall grid container end */}


    {/* 
            {
                imageList.length > 0 &&
                imageList.map((image)=>{
                    return(
                        <>
                            <Grid
                            item
                            xs={12} sm={6} md={3}
                            >

                                <MUICard image_ob={image} />

                            </Grid>
                        </>

                        )
                    }
                )
                } */}





{/* Info message bubble node*/}

{/* {!value_search_predict?<></>:<Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent="center" alignItems="center">
                        <Grid item
                        // xs={9} sm={9} md={9} lg={5}
                        sx={{
                            border: 'solid purple .15em',
                            borderRadius: 10,
                            backgroundColor: 'white',
                            paddingRight:'.5em',
                            paddingLeft:'.5em'

                        }}
                        >
                            
                        <p>{"Orbnode can make mistakes"}</p>

                        </Grid>
                </Grid>
            </Grid>}
 */}



    
        </Grid>

    {/*         
            <p>Hello there</p>
            <MUIStack_layout_streamlined/>
    */}
        
    </Box>
    // </>

    )
}