import React, { useState, useEffect } from 'react';
// import {MediaCard, CardInfo} from "./CardInfo"

// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

import MapComponent from './orbnode-map';
// import Config_Ingest from './Config_ingest'
// import config from './front-end.json'

const Orbnode_image_interface = () => {


  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('file:', typeof(file))
    setSelectedFile(file);
  };


  const [imageList, setImageList] = useState([]);
  const [showMap, setShowMap] = useState(false);

  // const address_backend = Config_Ingest()
  // const address_backend = '172.17.0.4:4242'
  // const address_backend = '3.90.25.43:8000'
  // const address_backend = Config_Ingest()['address'] + ':' + Config_Ingest()['port']
  // const address_backend = config['address'] + ':' + config['port']


  const fetchImageList = () => {
    // fetch('http://'+address_backend+'/data/image-list-return/', {

    fetch('http://www.orbnode.com:8000/data/image-list-return/',{
    // fetch('http://localhost:8000/data/image-list-return/',{
        // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-list-return/', {
    // fetch('http://172.18.0.2:8000/data/image-list-return/',{
        method: 'GET',
  })
      .then(response => response.json())
      .then(data => {
        setImageList(data.images);
        setShowMap(true);
      })
      .catch(error => {
        console.error('Error fetching image list:', error);
        setShowMap(false);
      });
  };


  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file_in', selectedFile);

      // fetch('http://'+address_backend+'/data/image-upload-single/', {

      fetch('http://localhost:8000/data/image-upload-single/', {
      // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-upload-single/', {
      // fetch('http://172.18.0.2:8000/data/image-upload-single/', {
          method: 'POST',
        body: formData,
      })
        .then(response => response.text())
        .then(data => {
          console.log(data);
        })
        .then(fetchImageList())
        .catch(error => {
          console.error('Error uploading image:', error);
        });
    }
  };


  useEffect(() => {
    fetchImageList();
  }, []);

// console.log(imageList.images[0])


console.log('test------')
console.log(imageList)
console.log(showMap)
console.log('-------test')


return (

    <div
    >
      {/* <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <br/>   

      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
      />
      <br/>   
 */}



{/* <p>
  test------
{toString(imageList)}
{toString(showMap)}
-------test
</p> */}

{imageList && showMap && <MapComponent imageList={imageList}/>}

    </div>
  );
};

export default Orbnode_image_interface;