import {Box, Grid} from '@mui/material'

import {UI_Grid_Item_Chat} from './UI_Grid_Item_Chat'

export const U_I_G_I_B = ({value, setValue, response, setResponse, setAnchorEl, set_popper_text,}) => {

    return(
        <Grid item

        xs={12} sm={9} md={7} lg={6} xl={4}

        style={{
            // position:'fixed',

            // height: '100%',
            // border: 'solid green',

            // display: 'flex', // Enable flexbox

            // alignItems: 'center', // Center content vertically
            // justifyContent: 'center', // Center content horizontally (optional)

            // width:'100%',


        }}

            // sx={{
            //     border:'solid pink'
            // }}
            >
            <UI_Grid_Item_Chat value={value} setValue={setValue} response={response} setResponse={setResponse} setAnchorEl={setAnchorEl} set_popper_text={set_popper_text}/>



        
        </Grid>
    )
}