import React, { useState, useEffect } from 'react';

import {Box} from '@mui/material';
import {TextField} from "@mui/material"
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import {UI_Grid_Item_Chat} from './UI_Grid_Item_Chat'


export const UI_Grid_View_Center_TextField_Custom = () => {


    const [value, setValue] = useState('')
    const [response, setResponse] = useState('')

    
    return(
            <>

            {/*
            Can use Box nested or Grid Container & Item, use height/width or grid/12 sizing
            Outer box 100 vh of viewport, vs nested elements %
            */}

                <Box
                sx={{
                height: "100vh", // Full viewport height
                // width:'100vh', // Full viewport width causes box & textbar to be less than 100vh, need % vs vh, 100%
                width:'100%', // Full viewport width causes box & textbar to be less than 100vh, need % vs vh, 100%

                display: "flex",
                justifyContent: "center", // Centers horizontally
                alignItems: "center", // Centers vertically
                bgcolor: "background.default", // Optional: background color

                border: "dashed purple 3px",
                }}
                >




                    <Box
                    sx={{
                        width:'60%', // Full viewport width causes box & textbar to be less than 100vh, need % vs vh, 100%

                        // flex flows, justify, and align allow toolbar with mini widgets

                        display: "flex", // flex flows, left-right (or reverse), top-down (or reverse)
                        justifyContent: "center", // Centers horizontally
                        alignItems: "center", // Centers vertically

                        // border:'solid grey',
                    }}
                    >



                        <TextField
                        sx={{
                            border:'solid white',
                        }}
                        >
                        </TextField>


                        {/* <InputAdornment position="end"> */}
                    <input
                    // ensures that only images can be selected
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-file"
                    type="file"
                    //*

                    // onChange={handleFileChange}

                    // suggests to the browser that the camera should be used for capturing images, especially on mobile devices
                    capture="environment"
                    />
                    <label htmlFor="upload-file">
                        <IconButton
                        // onClick={handleUpload}
                        variant="outlined" color='primary' component="span"
                        >
                        
                        <AddAPhotoIcon/>
                        {/* <MicIcon/> */}

                        </IconButton>
                    </label>
                {/* </InputAdornment> */}



{/* 
                        <UI_Grid_Item_Chat
                        value={value}
                        setValue={setValue}
                        response={response}
                        setResponse={setResponse}
                        // responseGeo={responseGeo}
                        // setResponseGeo={setResponseGeo}
                        >
                        </UI_Grid_Item_Chat> */}
            



                    </Box>


                </Box>
            </>
    )
}