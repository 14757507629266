import {Box, Grid, Typography, Paper, requirePropFactory} from "@mui/material"
import React, { useEffect, useRef } from 'react';

import {Message} from './UI_Grid_Item_Chat_Response'

import {Suggestions} from './UI_Grid_Item_Chat_Suggest'


import { useState } from 'react';


export const UI_Grid_Item_Dummy_Geo = ({imageList, value, setValue, response, setResponse}) => {

    const scrollRef = useRef(null);

    useEffect(() => {
      // Scroll to the bottom on component mount
      if (scrollRef.current) {
        // comment next line out to reverse the render scrolling to bottom of list and working upwards to scroll
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }, []);




    const [hoverIndex, setHoverIndex] = useState(null); // State to track hovered item

    // Function to handle selection
    const handleSelect = (item, index) => {
      setHoverIndex(index);
      // alert(`You selected: ${item}`);

      // function return or further use of selection from list can trigger highlight off by setting index of highlighted cell to null
      // setHoverIndex(null);
    };



    return(
        <Grid container
        
        ref={scrollRef}

        // xs={12} sm={4} md={4} lg={6}

        sx={{    
            boxShadow: '0px 0px 4px rgba(0, 0, 0, .3)', // Add drop shadow

            backgroundColor: 'white',

            pointerEvents:  'auto',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',

            
            // height:'80%',
            // height: '30vh',
            maxHeight: '20vh',

            // if i/o bar is column oriented, then small width will stack in a row left-right
            // width:'20em',

            overflowY: 'scroll', // Scroll upwards when overflow happens
            // overflowY: 'auto', // Scroll upwards when overflow happens

            '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar in Chrome/Safari/Webkit browsers
              },

            borderRadius:5.5,
            borderBottomLeftRadius: 0, 
            borderBottomRightRadius: 0,

                // ...(response ? { // Conditionally apply top border radius when response is populated
                //     borderBottomLeftRadius: 0,
                //     borderBottomRightRadius: 0
                // } : {}),


            // boxShadow:10,
            //   boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)', // Add drop shadow

            // MARGIN interacts with UI_Grid_Item_ChatResponse & Padding in UI_Grid_Item_Chat_System
            padding: '.5vh',
            paddingTop: '.5em',
            paddingBottom: '.5em',

            // padding: '0vh',
            // paddingLeft: '1vh',
            // paddingRight: '1vh',

            // paddingBottom: '1em',


              // border: 'solid grey',
        }}
        >


    {/* {response&&<Message isUser={true} text={value} avatarSrc={require('../images/orbnode_logo.png')}></Message>}
    {response&&<Message isUser={false} text={response} avatarSrc={require('../images/orbnode_logo.png')}></Message>} */}





{Suggestions().map((suggestion, index) => {
        return(
          
          // <Grid container>

          <Grid item
          margin='0.05vh'
          xs={12} lg={12}
          >


            <Paper
              elevation={1}
              sx={{
                // backgroundColor: '#f5f5f5',
                borderRadius: 4,
              }}
            

            key={index}
            style={{
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: hoverIndex === index ? 'lightgrey' : 'transparent',
              border: '1px solid #ddd',
              marginBottom: '0.5 vh',
              borderRadius: '4px',
            }}

              onMouseEnter={() => setHoverIndex(index)} // Highlight on hover
              onMouseLeave={() => setHoverIndex(null)} // Remove highlight on hover out
              onClick={() => handleSelect(suggestion, index)} // Trigger function on click
              >


                {suggestion}


            </Paper>
          </Grid>

          // </Grid>

          
          )
      }
    
  )
}




    {imageList && imageList.length > 0 &&
      imageList.map((image, index) => {
        return (

          <Grid item
          margin='0.05vh'
          xs={12} lg={12}
          >


          <Paper
            elevation={1}
            sx={{
              // backgroundColor: '#f5f5f5',
              borderRadius: 4,
            }}
          >


            <Grid container>

            {/* <Grid item
            // margin='2vh'
            xs={4} lg={4}
            >
            <img height='100px' src={require("../../../../../datasets/orbnode/database_images/"+image.file)}/>

            </Grid> */}


            <Grid item
            margin='.5vh'
            // xs={4} lg={4}
            >

            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                  {index}

                  {image.metadata["Image Model"]}

                  {image.metadata["GPS GPSLatitudeRef"]}

                  {image.metadata["GPS GPSLatitude"]}

                  {image.metadata["GPS GPSLongitudeRef"]}

                  {image.metadata["GPS GPSLongitude"]}

                  {image.metadata["GPS GPSImgDirectionRef"]}

                  {image.metadata["GPS GPSImgDirection"]}

                  </Typography>
              </Grid>


            </Grid>

          </Paper>
        

          </Grid>

    )
  })
}





        </Grid>
        
    )
}