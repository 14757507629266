import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

// const images = ["https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg",
//   'https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg',
//   ];

export const UI_Grid_Item_Image_Slider = ({images_in}) => {

  const images = images_in.map((image) => [image]);

  const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  const nextImage = () => setIndex((prevIndex) => (prevIndex + 1) % images.length);
  const prevImage = () => setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);

  return (
    <Box sx={{
      height:'15em',
      position: "relative",
      border:'transparent',
      borderRadius: 6,
      display: "flex",
      alignItems: "center",

      // border:'solid green',
      }}>
      {/* Single Image Changes Instead of Sliding */}
      <Box
        component="img"
        src={images[index]}
        // alt="Carousel Image"
        sx={{
          // borderRadius: 6,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the image covers the entire space
          display: "block",

          // border:'solid indigo',
        }}
      />

      {/* Navigation Buttons */}
      <IconButton onClick={prevImage} sx={{ position: "absolute", left: 10, backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white" }}>
        <ArrowBackIos />
      </IconButton>
      <IconButton onClick={nextImage} sx={{ position: "absolute", right: 10, backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white" }}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};