import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrthographicCamera } from '@react-three/drei';
import { Box, Grid, Button, Typography } from '@mui/material';
import * as THREE from 'three';

const ImagePlane = ({ imageUrl, scale, position, onSizeLoad }) => {
  const texture = useRef();
  const [geometry, setGeometry] = useState(null);
  const { scene } = useThree();

  useEffect(() => {
    new THREE.TextureLoader().load(imageUrl, (tex) => {
      tex.colorSpace = THREE.NoColorSpace; // Correct color encoding for modern Three.js
      texture.current = tex;
      const width = tex.image.width;
      const height = tex.image.height;
      onSizeLoad(width, height);
      const geo = new THREE.PlaneGeometry(width * scale, height * scale);
      setGeometry(geo);
    });
  }, [imageUrl, scale, onSizeLoad]);

  if (!texture.current || !geometry) return null;

  return (
    <mesh geometry={geometry} position={[position[0], position[1], 0]}>
      <meshBasicMaterial map={texture.current} transparent={true} />
    </mesh>
  );
};

const PolygonOverlay = ({ points, color, visible, scale, offset, label }) => {
  if (!visible) return null;

  const shape = new THREE.Shape();
  shape.moveTo(points[0] * scale + offset[0], -points[1] * scale + offset[1]);
  for (let i = 2; i < points.length; i += 2) {
    shape.lineTo(points[i] * scale + offset[0], -points[i + 1] * scale + offset[1]);
  }

  const geometry = new THREE.ShapeGeometry(shape);

  return (
    <>
      <mesh geometry={geometry}>
        <meshBasicMaterial color={color} transparent opacity={0.5} side={THREE.DoubleSide} />
      </mesh>
    </>
  );
};

export const UI_Grid_Item_Img_Layer_ThreeJSFiber_2 = ({ imageUrl, polygons }) => {
  const containerRef = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 1, height: 1 });
  const [containerSize, setContainerSize] = useState({ width: 1, height: 1 });
  const [visiblePolygons, setVisiblePolygons] = useState(polygons.map(() => true));

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.clientWidth,
          height: containerRef.current.clientHeight,
        });
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleImageLoad = (width, height) => {
    setImageSize({ width, height });
  };

  const togglePolygon = (index) => {
    setVisiblePolygons((prev) => prev.map((v, i) => (i === index ? !v : v)));
  };

  const scale = Math.min(containerSize.width / 2 / imageSize.width, containerSize.height / imageSize.height);
  const offsetX = -(imageSize.width * scale) / 2;
  const offsetY = (imageSize.height * scale) / 2;

  console.log(containerSize)

  return (
    <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', border: '3px solid green' }}>
      <Box sx={{ width: '100%', border: '2px solid white' }}>
        {containerSize.width > 0 && containerSize.height > 0 && (
          <Canvas
            orthographic
            camera={{ zoom: 1, position: [0, 0, 100] }}
            style={{ width: '100%', height: '100%' }}
          >
            <OrthographicCamera makeDefault position={[0, 0, 100]} zoom={1} />
            <ImagePlane imageUrl={imageUrl} scale={scale} position={[0, 0]} onSizeLoad={handleImageLoad} />
            {polygons.map((poly, i) => (
              <PolygonOverlay
                key={i}
                points={poly.points}
                color={poly.color}
                visible={visiblePolygons[i]}
                scale={scale}
                offset={[offsetX, offsetY]}
                label={poly.label}
              />
            ))}
          </Canvas>
        )}
      </Box>
      <Box sx={{ width: '100%', p: 2 }}>
        <Typography variant="h6">Toggle Polygons</Typography>
        {polygons.map((_, i) => (
          <Button
            key={i}
            variant={visiblePolygons[i] ? 'contained' : 'outlined'}
            onClick={() => togglePolygon(i)}
          >
            {visiblePolygons[i] ? `Hide` : `Show`} Layer {i + 1}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
