import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Image } from '@react-three/drei';
import { Box, Button } from '@mui/material';
import * as THREE from 'three';

const PolygonOverlay = ({ polygons, color, opacity, visible, imageDimensions, displayDimensions }) => {
  if (!visible) return null;

  const scaleX = displayDimensions.width / imageDimensions.width;
  const scaleY = displayDimensions.height / imageDimensions.height;

  return polygons.map((polygon, idx) => {
    const scaledVertices = polygon.map(([x, y]) => new THREE.Vector2(
      x * scaleX - displayDimensions.width / 2,
      -(y * scaleY - displayDimensions.height / 2)
    ));

    return (
      <mesh key={idx} position={[0, 0, 0.01]}>
        <shapeGeometry args={[new THREE.Shape(scaledVertices)]} />
        <meshBasicMaterial color={color} opacity={opacity} transparent />
      </mesh>
    );
  });
};

export const UI_Grid_Item_Img_Layer_ThreeJSFiber_1 = ({ imageUrl, imageDimensions, labels, overlayColor }) => {
  const [showOverlay, setShowOverlay] = useState(true);
  const [displayDimensions, setDisplayDimensions] = useState({ width: 10, height: 10 });

  useEffect(() => {
    const aspect = imageDimensions.width / imageDimensions.height;
    const baseSize = 10;
    setDisplayDimensions({
      width: aspect >= 1 ? baseSize : baseSize * aspect,
      height: aspect >= 1 ? baseSize / aspect : baseSize,
    });
  }, [imageDimensions]);

  return (
    <Box sx={{ width: '100%', height: '90%', padding: '0em', border:'solid indigo'}}>
      <Canvas orthographic camera={{ zoom: 100, position: [0, 0, 10] }}>

        <OrbitControls enableRotate={false} enableZoom={false} />

        <Image
        sx={{
          objectFit: "cover", // Ensures no distortion, just cropping
          width: "100%",
          height: "100%",}}

          url={imageUrl}
          scale={[displayDimensions.width, displayDimensions.height]}
          />

        <PolygonOverlay
          polygons={labels}
          color={overlayColor}
          opacity={0.6}
          visible={showOverlay}
          imageDimensions={imageDimensions}
          displayDimensions={displayDimensions}
        />
      </Canvas>

      <Button
      // sx={{margin:'50px'}}
        variant="contained"
        // sx={{ position: 'absolute', top: 10, left: 10, zIndex: 10 }}
        onClick={() => setShowOverlay((prev) => !prev)}
      >
        {showOverlay ? 'Hide Overlay' : 'Show Overlay'}
      </Button>
    </Box>
  );
};
