import React, { useState, useRef } from "react";
import { TextField, InputAdornment, Popper, Paper, ClickAwayListener } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const UI_Grid_Item_Place_Form_popper = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <TextField
        inputRef={anchorRef}
        variant="outlined"
        placeholder="Search..."
        onFocus={() => setOpen(true)}

        fullWidth
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ width: anchorRef.current?.offsetWidth }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper style={{ padding: 16 }}>Popper Content</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

