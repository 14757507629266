import React, { useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Line } from 'react-konva';
import useImage from 'use-image';
import { Paper } from '@mui/material';

export const UI_Grid_Item_Img_Konva_2 = ({ src }) => {
  const [image] = useImage(src);
  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const handleClick = (event) => {
    const stage = event.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    setPoints([...points, pointerPosition.x, pointerPosition.y]);
  };

  const handlePolygonComplete = () => {
    setLines([...lines, points]);
    setPoints([]);
  };

  return (
    <Paper elevation={3} sx={{ overflow: 'hidden', display: 'inline-block' }}>
      <Stage width={800} height={600} onClick={handleClick}>
        <Layer>
          <KonvaImage image={image} />
          {lines.map((linePoints, i) => (
            <Line
              key={i}
              points={linePoints}
              stroke="blue"
              closed
              strokeWidth={2}
              fill="rgba(0,0,255,0.2)"
            />
          ))}
          <Line
            points={points}
            stroke="red"
            strokeWidth={2}
            closed={false}
          />
        </Layer>
      </Stage>
    </Paper>
  );
};
