import {Box, Stack, Divider, Grid} from '@mui/material'

// import { MUIStack_layout_streamlined } from './MUIStack_layout_streamlined'

import App from '../App'


import {MUICard} from './data_card'
// import {MUICard} from './data_card_manual'
import {UI_Grid_Item_Card_Poster} from './UI_Grid_Item_Card_Poster'


// import Config_Ingest from './Config_ingest'

import React, { useState, useEffect } from 'react';


// MUI Box is a div wrapper
// useful because the sx={} prop allows customization of MUJI theme

import MapComponent from './data_map'
import {UI_Grid_Item_Map} from './UI_Grid_Item_Map';



export const UI_Grid_Item_Cards = () => {

    const [imageList, setImageList] = useState([]);
    // const address_backend = 'localhost:8000'
    const address_backend = 'www.orbnode.com:8000'
    // const address_backend = Config_Ingest()

    const fetchImageList = () => {

        fetch('http://'+address_backend+'/data/image-list-return', {

        // fetch('http://localhost:8000/data/image-list-return/',{
        // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-list-return/', {
        // fetch('http://172.18.0.2:8000/data/image-list-return/',{
            method: 'GET',
    })
        .then(response => response.json())
        .then(data => {
            setImageList(data.images);
        })
        .catch(error => {
            console.error('Error fetching image list:', error);
        });
    };

    useEffect(() => {
        fetchImageList();
        // setImageList(['https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg',
        //     'https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg',
        //     'https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg','https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg'
        // ])

    }, []);


    // console.log(imageList[0])



    return(
        <>
        {/*
        Grid has 2 variations

        Grid container, for the parent
        &
        Grid item, for children
        
        (1) Grid container elements are flex by defaults
        Grid item elements are centered by default
        */}
             

        {/* Stack component for testing interactivity between components */}
        {/* <MUIStack_layout_streamlined/> */}


        {/*
        Grid breakpoints for devices:
        xs: mobile, sm: tablet, md: desktop, lg & xl: large monitors
        integer values indicate n/12 of the available columns to occupy
        when the viewport satisfies that breakpoint constraints
        */}




        {/* <UI_Grid_Item_Map imageList={imageList}/> */}





        <Grid
        //(1) container
        container

        // if background of Grid container is on
        // row and column spacing will push container width
        // without background color; gird does not occlude prior or following boxs, divs, stacks, grids, etc.
        // and it wont block if my={size} below

        // bgcolor='primary.light'

        // my=> margin vertical, y-axis
        // Will only move if more than margin of prior cpmponent
        // if prior component margin is my={6}
        // must use my={6 + 1} => {7}
        // my={10}

        // // my must be 0 and column spacing 0 for border to be visible otherwise it is negative adjusted to the left and up
        // my={0}
        // columnSpacing={0}
        // sx={{border:'solid purple 1em'}}
        // or no spacing
        // but any column or row spacing throws this off

        // Grid container spacing prop
        // spacing={2}

        // row & column spacing
        // if background of Grid container is on
        // row and column spacing will push container width
        // rowSpacing={6}

        // rowSpacing={2}
        // columnSpacing={2}

        // column/row spacing will impact and push elements foward by the space amount
        // even the Stack_layout_streamlined above
        style={{

        pointerEvents:  'auto',

        // padding:'1em',

        // display: 'flex',
        // // flexDirection: 'row-reverse',
        // flexDirection: 'row',
        // justifyContent: 'flex-end',

        // overflowY: 'auto', // Scroll upwards when overflow happens

        // height: '100px',
        // height: '100%',
        // height: '90vh',
        // height: '100vh',

        // width: '80%',
        // width: '80%',

        // padding: '2em',

        //   justifyContent:"center",
        //   alignItems:"center",


        // border: 'solid green',
        }}

        sx={{
            
            '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar in Chrome/Safari/Webkit browsers
              },

            
            // padding: '0.75em',

              
            //   border:'solid purple .3em'
            
            }}
        >
        


        {
            imageList.length > 0 &&
            imageList.map((image)=>{
                return(
                    <>
                        <Grid item
                                  xs={12} sm={6} md={6} lg={3} xl={3}

                        sx={{
                            // padding: '.25em',
                            // padding: '1em',
                            // margin will cause overflow vs. padding if using lg, etc  = {3} to have size lg ={x} across
                            // ex. 3 across with lg={4} might overflow to only 2 across with margin
                            // margin: '.25em',

                            // height: '100vh',


                            // border:'solid magenta 1px',

                            padding: '0.5em',


                        }}
                        
                        // xs={12} sm={6} md={3} lg={2} xl={2}
                        >

                            {/* 
                            <MUICard
                            image_ob={image}
                            /> */}

                            
                            <UI_Grid_Item_Card_Poster
                            image_ob={image}
                            />


                            

                        </Grid>
                    </>

                    )
                }
            )
            }



    
    </Grid>

{/*         
        <p>Hello there</p>
        <MUIStack_layout_streamlined/>
 */}
        
        </>
    )
}
