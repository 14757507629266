import {useState} from 'react';

import {Grid, Box} from "@mui/material"
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import MicIcon from '@mui/icons-material/Mic';

export const UI_Grid_Item_ToolSelect = () => {

    const [value, setValue] = useState('')

    return(
        

        <Grid container
        // lg={12}
        // when page loads text field is the selected component where the cursor begins
        autoFocus

        sx={{
            // position: 'fixed',
            // bottom: 0,
            // left: 0,
            // right: 0,

            display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-end',

            // alignItems:"center",
            justifyContent: 'center',

            borderRadius:4,
            // borderTopLeftRadius: 0, 
            // borderTopRightRadius: 0, 
            // boxShadow: '10',
            // backgroundColor: 'rgba(0,0,0, 0.2)',
            // backgroundColor: 'transparent'
            backgroundColor: 'white',
            padding: '1em',

            // width: '100vh',

            // border: 'solid gray', // Default border color
            // borderWidth: '.1em', // You can adjust the border size here
            
            // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Add drop shadow
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)', // Add drop shadow

            color: 'black', // Label color when focused
        }}


        // value={value}

        // onChange={(i)=>{
        //     setValue(i.target.value)
        //     }
        // }
        >

<Grid item sx={{ border: 'solid green',}}>
        <img         
            src={require('../images/orbnode_logo.png')} width={'40em'}/>
        </Grid>

        <Grid item sx={{ border: 'solid green'}}>
            <TextsmsIcon
            fontSize='large'>
            </TextsmsIcon>
        </Grid>

        <Grid item sx={{ border: 'solid green'}}>
            <AddAPhotoIcon
            fontSize='large'>
            </AddAPhotoIcon>
        </Grid>

        <Grid item sx={{ border: 'solid green'}}>
            <MicIcon
            fontSize='large'>
            </MicIcon>
        </Grid>


        </Grid>
    )
}