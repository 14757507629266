import {Box, Card, CardContent, CardActions, CardMedia, Button, Typography} from '@mui/material'
import {Breadcrumbs, Link} from '@mui/material'

import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import AudioPlayer from './data_audio'






export const MUICard = (props) =>{


const image_ob_in = props.image_ob


    return(

            <>

            <Card
            // borderRadius for more custom style
            style={{
                    borderRadius:'10px',
                    // width: '50%',
                    // height:'100px'
                    // backgroundColor:'silver'
                    // border:'solid black .2px'
                }}
                variant="outlined"
            >

                {/* (1) card content */}
                <CardContent>
                    {/* items after/before collapse */}
                    <Breadcrumbs
                    itemsAfterCollapse={1}
                    itemsBeforeCollapse={2}
                    maxItems={2}

                    aria-label='breadcrumb'
                    separator='/'
                    
                    >
                        {/* links nested within breadcrumb component
                        only text for current page is not created as link */}
                        <Link style={{fontSize:'1em'}} variant='p' color='secondary' underline='hover' href='#'>alexdelapaz</Link>
                        <Link style={{fontSize:'1em'}} color='p' underline='hover' href='#'>skatespots</Link>
                    </Breadcrumbs>
                </CardContent>


                {/* card component contain
                (1) card media (2) card content (3) card actions */}

                    {/* (1) card media */}
                    {/* add images to card with CardMedia */}
                    <CardMedia
                    component='img'
                    // height='150px'
                    // only need height or width for img to size
                    image={require("../../public/datasets/orbnode/database_images/"+image_ob_in.file)}
                    height='200em'
                    >
                    </CardMedia>


                {/* (1) card content */}
                <CardContent>
                    <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    >
                        Text note
                    </Typography>

                    <Typography
                    variant='body2'
                    color='text.secondary'
                    >
                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum 
                    </Typography>
                </CardContent>


                {/* (3) card actions */}
                {/* add buttons to Card */}
                <CardActions>
                        <Button size='small'>
                            Add to dataset
                        </Button>

                        <Button size='small'>
                            add to trip
                        </Button>
                </CardActions>


            {/* <AudioPlayer/> */}
    
            </Card>
            </>

        )
    }