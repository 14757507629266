import {useState, useRef} from "react";

import {Grid, Paper, Popper} from '@mui/material';
import {UI_Grid_Item_Cards} from './UI_Grid_Item_Cards';
import {U_I_G_I_B} from './UI_Grid_Item_Bar';
import {useTheme, Box, TextField, Button} from '@mui/material';

import {UI_Grid_Item_Dummy_Geo} from './UI_Grid_Item_Dummy_Geo'

import {UI_Grid_Item_Place_Form} from './UI_Grid_Item_Place_Form'
import {UI_Grid_Item_Place_Form_0} from './UI_Grid_Item_Place_Form_0'
import {UI_Grid_Item_Place_Form_1_test} from './UI_Grid_Item_Place_Form_1_test'
import {UI_Grid_Item_Place_Form_popper} from './UI_Grid_Item_Place_Form_popper'

import {UI_Grid_Item_Map} from './UI_Grid_Item_Map';


import {UI_Grid_Item_Img_Konva_1} from './UI_Grid_Item_Img_Konva_1'
import {UI_Grid_Item_Img_Konva_2} from './UI_Grid_Item_Img_Konva_2'

import {UI_Grid_Item_Img_Layer_ThreeJSFiber_1} from './UI_Grid_Item_Img_Layer_ThreeJSFiber_1'
import {UI_Grid_Item_Img_Layer_ThreeJSFiber_2} from './UI_Grid_Item_Img_Layer_ThreeJSFiber_2'
import {UI_Grid_Item_Img_Layer_ThreeJSFiber_3} from './UI_Grid_Item_Img_Layer_ThreeJSFiber_3'

import {UI_Grid_Item_Img_Layer_DeckGL_1} from './UI_Grid_Item_Img_Layer_DeckGL_1'
import {UI_Grid_Item_Img_Layer_DeckGL_2_CoordSys} from './UI_Grid_Item_Img_Layer_DeckGL_2_CoordSys'
import {UI_Grid_Item_Img_Layer_DeckGL_3_CoordSys_Bitmap} from './UI_Grid_Item_Img_Layer_DeckGL_3_CoordSys_Bitmap'


import labelsData from './labels.json';

export const U_G_I_T_V = () => {


  const [value, setValue] = useState('')
  const [response, setResponse] = useState('')  

  const [anchorEl, setAnchorEl] = useState(null);
  const [popper_text, set_popper_text] = useState(null)

  
  const textFieldRef = useRef(null);


  const theme = useTheme();
  const color_theme = theme.palette.background.default
  

  return (
    <Box sx={{
      height: '100svh',
      display: 'flex',
      flexDirection: 'column',

      WebkitOverflowScrolling: 'touch', // Smooth scrolling on Safari
      pointerEvents: 'none',

    }}
    >

    {/* Top Bar */}
    <Box sx={{
      // zindex allows middle of page to scroll under shadow produced by bar, instead of appearing like it scrolls up in beteen the bar and the shadow it casts
      zIndex:5000,

      pointerEvents: 'auto',

      // zIndex: 6000,
      // position: 'fixed', // Keep it fixed at the top
      // width: '100%',

      // overflowY: 'auto', // Scroll upwards when overflow happens

      // minHeight: '6svh',
      // height:'10vh',

      padding:'0.5em',
      
      // backgroundColor: 'primary.main',
      // backgroundColor: 'white',

      borderBottom: 'solid rgba(256, 256, 256, 0.2) .1em', // Shadow under the top bar
      // border: 'solid green',

      // borderBottom: 'solid rgba(255, 255, 255, 0.2) .1em',
      // border: 'solid green',
      // makes a grey shadow like effect when using transparency on shadow over black nightmode ui
      // boxShadow: '0px 2px 2px rgba(200, 200, 200, 0.1)', // Shadow under the top bar
      // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Shadow under the top bar

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',

      
      alignItems: 'center',
      justifyContent: 'center',

      }}>




        <Box
          sx={{
            // backgroundColor:'rgb(256,256,256,0.3)',
            // backgroundColor:'white',
            // borderRadius:'100px',

        
          }}
          >    
          <img src={require('../images/orbnode_logo.png')} width={'60em'}/>
        </Box>
        
        <Box 
        sx={{
          fontSize: theme.typography.h5.fontSize,
          mx:'20px',
          }}
        >
          orbnode
        </Box >

      
    </Box>



    {/* Middle Area */}
    <Box
    
    // sx={{
    //   flex: 1, padding: 2, justifyContent: 'center', alignItems: 'center',
    //   overFlowY: 'scroll',}}

    sx={{
      pointerEvents: 'auto',

        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'flex-end',
        // flexDirection: 'row-reverse',

        flexGrow: 1,

        overflowY: 'auto', // Scroll upwards when overflow happens

        // height: '100svh',
        // height: '90%',

        // padding: '1vh',
        // padding: '.25em',
        // paddingBottom: '1em',
        // margin will cause overflow vs. padding if using lg, etc  = {3} to have size lg ={x} across
        // ex. 3 across with lg={4} might overflow to only 2 across with margin
        // margin: '.25em',

        
        WebkitOverflowScrolling: 'touch', // Smooth scrolling on Safari

        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in Chrome/Safari/Webkit browsers

          border: 'solid green 5px',
        },


        // '@media (max-width: 90px)': {
        //   height: '100dvh', // Alternative for devices with dynamic toolbars
        // },

        // '@media (max-width: 90px)': {
        //   height: '100svh', // Alternative for devices with dynamic toolbars
        // },

        // border:'solid purple',

      }}
      
      >

      {/* imageUrl="https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg"
      polygonPoints={polygonPoints}
      overlayColor="rgba(0,128,255,0.4)" */}

      {/* <div style={{ border:'solid green', width: '100%', height: '600px' }}> */}



      {/* <UI_Grid_Item_Img_Konva_1

        imageUrl="https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg"
        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"
        // height='100%'
        // height='1772px'
        polygons={[
          { points: [600, 50, 200, 50, 200, 200, 50, 600], color: 'rgba(199, 21, 166, 0.8)'},
          { points: [600, 500, 600, 500, 800, 200, 700, 50], color: 'rgba(23, 186, 39, 0.8)'},
          { points: [50, 300, 600, 300, 200, 600, 50, 450], color: 'rgba(77, 23, 186, 0.8)', label:'3'},
        ]}
      /> */}


      {/* <UI_Grid_Item_Img_Konva_2
      img='https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg'
      /> */}



      {/* <UI_Grid_Item_Img_Layer_ThreeJSFiber_1
        imageUrl={labelsData.imageUrl}
        imageDimensions={labelsData.imageDimensions}
        labels={labelsData.polygons}
        overlayColor="purple"
      /> */}


      {/* <UI_Grid_Item_Img_Layer_ThreeJSFiber_2
        // imageUrl="https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg"
        // imageUrl = {"https://corsproxy.io/?" + encodeURIComponent("https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg")}

        // imageUrl={require('../images/orbnode_logo.png')}

        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"
        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"

        imageUrl='https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg'

        // height='100%'
        // height='1772px'
        polygons={[
          { points: [600, 50, 200, 50, 200, 200, 50, 600], color: 'rgba(199, 21, 166, 0.8)'},
          { points: [600, 500, 600, 500, 800, 200, 700, 50], color: 'rgba(23, 186, 39, 0.8)'},
          { points: [50, 300, 600, 300, 200, 600, 50, 450], color: 'rgba(77, 23, 186, 0.8)', label:'3'},
        ]}
      /> */}


        {/* <UI_Grid_Item_Img_Layer_ThreeJSFiber_3
        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"
        imageUrl="https://images.unsplash.com/photo-1584395630827-860eee694d7b"

        /> */}


        {/* <UI_Grid_Item_Img_Layer_DeckGL_1
        imageUrl="https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg"
        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"
        // imageUrl='../images/orbnode_logo.png'
        annotations={
          [
            {
              label: 'Car',
              polygon: [
                [600, 50],
                [200, 50],
                [200, 90],
                [300, 90],
              ],
              color: [255, 0, 0, 100],
            },
            {
              label: 'Tree',
              polygon: [
                [300, 100],
                [400, 100],
                [-180, -90],
                [-122.45, 90],
              ],
              color: [0, 255, 0, 100],
            },
          ]
        }
        /> */}


        {/* <UI_Grid_Item_Img_Layer_DeckGL_2_CoordSys
        // imageUrl='https://m.media-amazon.com/images/I/71-3SKlQNUL._AC_UF894,1000_QL80_.jpg'
        // imageUrl="https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg"
        // imageUrl={require("https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg")}
        // imageUrl="http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D"
        // imageUrl={require("http://www.orbnode.com:8000/sub-api/MjAyNS0wMy0wNFQwNTo1NzowNi4zMTc3NjIrMDA6MDB8RmlsZW5hbWU6UFhMXzIwMjQwNDEzXzE4MDc1MzMxMi5NUA%3D%3D")}
        // imageUrl='../images/orbnode_logo.png'
        // imageUrl={require('../images/orbnode_logo.png')}
        // imageUrl = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/sf-districts.png'
        imageUrl='https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg'
        annotations={
          [
            // {
            //   label: 'Car',
            //   polygon: [
            //     [600, 50],
            //     [200, 50],
            //     [200, 90],
            //     [300, 90],
            //   ],
            //   color: [255, 0, 0, 100],
            // },
            // {
            //   label: 'Tree',
            //   polygon: [
            //     [300, 100],
            //     [400, 100],
            //     [-180, -90],
            //     [-122.45, 90],
            //   ],
            //   color: [0, 255, 0, 100],
            // },


            // {
            //   label: 'Car',
            //   polygon: [
            //     [600, 50],
            //     [200, 50],
            //     [200, 200],
            //     [300, 200],
            //   ],
            //   color: [255, 0, 0, 100],
            // },
            // {
            //   label: 'Tree',
            //   polygon: [
            //     [300, 100],
            //     [400, 100],
            //     [400, 200],
            //     [300, 200],
            //   ],
            //   color: [0, 255, 0, 100],
            // },


            {
              label: 'Car',
              polygon: [
                [600, 50],
                [200, 50],
                [200, 200],
                [50, 600],
              ],
              color: [255, 0, 0, 100],
            },
            {
              label: 'Tree',
              polygon: [
                [600, 500],
                [600, 500],
                [800, 200],
                [700, 50],
              ],
              color: [0, 255, 0, 100],
            },
            {
              label:  'Dog',
              polygon:  [
                [50, 300],
                [600, 300],
                [200, 600],
                [50, 450]
              ],
              color:  [150,150,150,100]
            }

          ]
        }
        /> */}




<UI_Grid_Item_Img_Layer_DeckGL_3_CoordSys_Bitmap
        imageUrl='https://storage.googleapis.com/p-oaf-ibe-back-00e-strapi-uploads/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555/xlarge_PREVIEW_SPOT_6_PMS_202501151821025_ORT_7243207101_7b29d7b555.jpg'
        // imageUrl = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/sf-districts.png'
        // imageUrl='https://m.media-amazon.com/images/I/71-3SKlQNUL._AC_UF894,1000_QL80_.jpg'
        // imageUrl={require('../images/orbnode_logo.png')}
        // imageUrl="https://www.thetravelteam.com/wp-content/uploads/2018/08/venice.jpg"

        annotations={
          [


            {
              label: 'Car',
              polygon: [
                [600, 50],
                [200, 50],
                [200, 200],
                [50, 600],
              ],
              color: [255, 0, 0, 100],
            },
            {
              label: 'Tree',
              polygon: [
                [600, 500],
                [600, 500],
                [800, 200],
                [700, 50],
              ],
              color: [0, 255, 0, 100],
            },
            {
              label:  'Dog',
              polygon:  [
                [50, 300],
                [600, 300],
                [200, 600],
                [50, 450]
              ],
              color:  [150,150,150,100]
            }

          ]
        }
        />




      {/* </div> */}

      {/* <UI_Grid_Item_Cards/> */}
      

      {/* <UI_Grid_Item_Place_Form/> */}

      {/* <UI_Grid_Item_Place_Form_0/> */}
      
      {/* <UI_Grid_Item_Place_Form_popper/>
      <br/>
      <br/>
      <br/> */}

      {/* <UI_Grid_Item_Place_Form_1_test/> */}




      {/* buffer content, for when interaction bar is absolute positioned, vs as entire bar after page content */}
      {/* <Box
      sx={{
        // test content to make box border expand, still has height without content, but box is line
        // height:'8vh',
        // marginBottom: '10vh',
        // border:'solid purple',
      }}>
      </Box> */}




    </Box>



    {/* Bottom Bar */}
    <Grid container

    className="NAV-BOTTOM"

    sx={{

      // zIndex:5000,
      pointerEvents: 'auto',

      // display: 'flex',
      // flexDirection: 'column',


      // apply to get floating and scroll under interaction bar, vs the bar and its container being an item after the page content
      // position: "fixed",
      // bottom: 0,
      // width: "100%",
      // height: '6vh',


      alignItems: 'center',
      justifyContent: 'center',


      padding:'0.5em',

      // for use with absolute positioning, see through but veil scroll effect or background color

      backgroundColor: color_theme,
      // backgroundColor: 'green',

      // backgroundColor: 'rgba(0,0,0,0.7)',
      // backgroundColor: 'rgba(0,0,0)',
      // backgroundColor: color_theme,
      backgroundColor: 'transparent',

      // borderTop: 'solid rgba(256, 256, 256, 0.2) .1em', // Shadow under the top bar
      // borderTop: 'solid rgba(73, 73, 73, 0.9) .05em',
      // borderTop: 'solid rgba(0,0,0,0.2) .05em',
      // borderTop: 'solid grey 1px',

      // marginTop: '.05em',
      // boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)', // Shadow above the bottom bar

      // backgroundColor: 'primary.main',
      // backgroundColor: 'transparent',

      // border: 'solid black .25em',
      // border: 'solid indigo'
      }}>




        <Popper
        open={Boolean(anchorEl && popper_text)} //use Boolean(anchorEl && popper_text) and not anchorEl && popper_text alone, due to null initialization
        // open={popper_text}
        // open={anchorEl}

        anchorEl={anchorEl}

        placement="top-start"

        modifiers={[
          {
            name: "offset",
            options: {
              offset: [-50, 10], // Adjust for spacing above the TextField
            },
          },
        ]}
        
        style={{
          zIndex: 1300,
          width: textFieldRef.current,
        
        }}
        >



        <Box

        sx={{
          // position:'absolute',
          width:'30vh',
          // border:'solid green'
        }}
        >

          <UI_Grid_Item_Dummy_Geo imageList={{}} ></UI_Grid_Item_Dummy_Geo>

        </Box>


    </Popper>




      {/* <UI_Grid_Item_Dummy_Geo imageList={{}} ></UI_Grid_Item_Dummy_Geo> */}


      <U_I_G_I_B value={value} setValue={setValue} response={response} setResponse={setResponse} setAnchorEl={setAnchorEl} set_popper_text={set_popper_text}/>



    </Grid>
    
    
  </Box>
  );
};
