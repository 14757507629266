import React, { useState } from "react";
import { TextField, Autocomplete, Chip, Box } from "@mui/material";

const placeTypesData = [
  { label: "Restaurant", subcategories: ["Fast Food", "Fine Dining"] },
  { label: "Retail", subcategories: ["Clothing", "Electronics"] },
  { label: "Gym", subcategories: [] },
];

export const UI_Grid_Item_Place_Form_1_test = () => {
  const [selectedPlaceTypes, setSelectedPlaceTypes] = useState([]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedPlaceTypes(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 400 }}>
      {/* Place Name and Address */}
      <TextField label="Place Name" fullWidth />
      <TextField label="Address" fullWidth />

      {/* Latitude & Longitude */}
      <TextField label="Latitude" type="number" fullWidth />
      <TextField label="Longitude" type="number" fullWidth />

      {/* Place Type Dropdown */}
      <Autocomplete
        multiple
        options={placeTypesData.flatMap(type => [type.label, ...type.subcategories])}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={option} label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => <TextField {...params} label="Place Type" />}
        onChange={handleSelectionChange}
      />
    </Box>
  );
}
