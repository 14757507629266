import './App.css';

import {UI_Grid_View_Trie} from './components/UI_Grid_View_Trie';
import {UI_Grid_View_Center} from './components/UI_Grid_View_Center_TextField';

import {UI_Grid} from './components/UI_Grid';
import {UI_Grid_Inverse} from './components/UI_Grid_Inverse'
import {UI_Grid_Inverse_1} from './components/UI_Grid_Inverse_1'
import {UI_Grid_Inverse_2} from './components/UI_Grid_Inverse_2'

import {UI_Grid_View_Center_TextField} from './components/UI_Grid_View_Center_TextField'
import {UI_Grid_View_Center_TextField_Custom } from './components/UI_Grid_View_Center_TextField_Custom';

import {Data_grid} from './components/data_grid'
import {UI_Grid_Item_Cards} from './components/UI_Grid_Item_Cards'

import MapComponent from './components/data_map'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import {U_G_I_T_V} from './components/UI_Grid_View_Trie_Vertical'


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  return (
    <div className="App">

      {/* TODO primary, secondary, etc colors lighter dark-grey for dark theme */}
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />




        <U_G_I_T_V/>

        {/* <UI_Grid_View_Trie/> */}
        {/* <UI_Grid_View_Center_TextField/> */}
        {/* <UI_Grid_View_Center_TextField_Custom/> */}
        
        {/* <UI_Grid/> */}
        {/* <UI_Grid_Inverse/> */}
        {/* <UI_Grid_Inverse_1/> */}
        {/* <UI_Grid_Inverse_2/> */}

        {/* <Data_grid/> */}
        {/* <UI_Grid_Item_Cards/> */}

        {/* <MapComponent/> */}




      </ThemeProvider>

    </div>
  );
}

export default App;
