import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const UI_Grid_Item_Place_Form_0 = () => {
  const [imagess, setImage] = useState(null);
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result); // ✅ Correct base64 preview
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Form Data:", { ...formData, imagess });
    console.log({imagess});
  };

  return (
    <Card sx={{ maxWidth: 400, mx: "auto", p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h6" textAlign="center">
        Upload Image & Fill Form
      </Typography>

      {/* Image Upload */}
      {/* <Box
        sx={{
          border: "2px dashed gray",
          borderRadius: 2,
          textAlign: "center",
          p: 2,
          cursor: "pointer",
          "&:hover": { bgcolor: "action.hover" },
        }}
        onClick={() => document.getElementById("image-upload")?.click()}
      > */}
        {imagess ? (
          // <CardMedia component="img" src={image} alt="Preview" sx={{ borderRadius: 2, maxHeight: 200 }} />

          <CardMedia
          component="img"
          src={imagess}
          alt="Preview"
          sx={{ borderRadius: 2, maxHeight: 200, objectFit: 'contain', width: '100%' }}
          onClick={() => document.getElementById("image-upload")?.click()}
/>

        ) : (
          <Typography color="textSecondary">
            <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }}
                    onClick={() => document.getElementById("image-upload")?.click()}

/>
            Click to upload or drag & drop
          </Typography>
        )}
      {/* </Box> */}
      <input
        id="image-upload"
        type="file"
        accept="image/*"
        hidden
        onChange={handleImageUpload}
      />

      {/* Form Fields */}
      <TextField label="Name" name="name" value={formData.name} onChange={handleChange} fullWidth />
      <TextField label="Email" name="email" value={formData.email} onChange={handleChange} fullWidth />

      {/* Submit Button */}
      <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
        Submit
      </Button>
    </Card>
  );
};
