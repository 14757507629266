import React from 'react';
import {MapContainer, TileLayer, Marker, Popup, ZoomControl, GeoJSON, WMSTileLayer} from 'react-leaflet';


import L from 'leaflet';
// import arrowImage from '../images/arrow.svg'
import 'leaflet-rotatedmarker';
import {useState, useEffect} from 'react';

import {ImageOverlay} from "react-leaflet";

// import GeoTIFFOverlay from './data_geotiff'

// import './styles.css'
// import {MUICard} from './data_card'


// import img_in from '/datasets/orbnode/database_images/PXL_20240413_180753312.MP.jpg'



export const UI_Grid_Item_Map = ({ imageList }) => {

        // Example coordinates (replace with your own)
        const latitude = 40.7128; // Example latitude (e.g., New York City)
        const longitude = -74.0060; // Example longitude

  const bounds = [
    [38.98680201653501, -77.13808562151067],
    [38.83743269570888, -76.94730993325301]
  ];
  
  // Adjust icon paths for Leaflet to work with React
  delete L.Icon.Default.prototype._getIconUrl;
  
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
    iconUrl: require("leaflet/dist/images/marker-icon.png").default,
    shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
  });



  if (imageList.length <= 0){
    return (<></>)
  }

  
  // const [lat, lon] = [imageList[0].metadata['GPS GPSLatitude'], -1*imageList[0].metadata['GPS GPSLongitude']]


              
  // direction = (direction + angle)%360
  const direction = 0

  var markerIcon = new L.divIcon({
    // html: `<div style="transform: rotate(${direction}deg);">&#10148;</div>`,
    // html: `<img src="${arrowImage}" style="transform: rotate(${direction}deg); width: ${40}px; height: ${40}px;" />`,
    // html: `<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDUGavpaVwUv8P_aNPXT4QoKYKt0DbpuvEDw&usqp=CAU" style="transform: rotate(${direction}deg); width: ${20}px; height: ${20}px;" />`,
    html: `<img src="https://huggingface.co/front/assets/huggingface_logo-noborder.svg" style="transform: rotate(${direction}deg); width: ${64}px; height: ${64}px;" />`,
    // iconUrl: "https://huggingface.co/front/assets/huggingface_logo-noborder.svg",
    className: 'arrow-marker-icon',
    iconSize: [64, 64],
    iconAnchor: [64/2, 64/2],
  });


  return (
<div
style={{
  pointerEvents:  'auto',
  }}>

{/*
no need for marginTop, so app can be single page with fixed nav bar top & search bar etc. bottom
<MapContainer center={[lat,lon]} zoom={18} style={{height:"100vh", marginTop: "1em"}}> */}
<MapContainer

center={[latitude,latitude]}
// center={[0,0]}

zoom={12}
// zoom={2}

maxZoom={19}

style={{
  // pointerEvents:  'auto',

  height:"50vh",
  width:"50vh",
  // height:"100vh",
  marginTop: "0em",

  borderRadius:20,
  borderBottomLeftRadius: 0, 
  borderBottomRightRadius: 0,

}}
// if zoom control is not false or declared, zoom control will render top left
zoomControl={false}
>



      <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      // url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.png"

      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      
      maxZoom={20}
      
      />

      {imageList.map((image, index) => {

       

        // Example coordinates (replace with your own)
        const latitude = 40.7128; // Example latitude (e.g., New York City)
        const longitude = -74.0060; // Example longitude


            

          return (

          <Marker
            key={index}
            position={[latitude, latitude]}
            icon={markerIcon}
            // icon={directionIcon}
            // rotationAngle={direction} // set the rotation angle
            >

            

            <div className='leaflet-react-popup'>


            <Popup
            sx={{
              leafletPopupScrolled: 'black',
              /* Add other styles here if needed */
            }}
            >
              
                {/* <MUICard
                // custom class added in css for popup background modification
                className="customPopup"
                image_ob={image} /> */}


                {/* <img src={require("../../../../../datasets/orbnode/database_images/"+image.file)} alt={image.file} style={{ maxWidth: '50px'}} />
                <p>{magDir}</p> */}

                {/* <p>{image.saved}</p>
                <p>{direction}</p>
                <p>{directionMultiplier}</p>

                <p>{image.metadata['GPS GPSImgDirection']}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection']}</p>
                <p>{image.metadata['GPS GPSImgDirection'].length}</p>

                <p>{image.metadata['GPS GPSImgDirection'][0]}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection'][0]}</p>

                <p>{image.metadata['GPS GPSImgDirection'][0].split('/')[0]}</p>
                <p>{image.metadata['GPS GPSImgDirection'][0].split('/')[1]}</p>
                <p>{typeof image.metadata['GPS GPSImgDirection'][0].split('/')}</p> */}
            </Popup>


            </div>

          </Marker>
          )
      }
      )
      }




        {/* <Marker
            key={42}
            position={[38.90870833333333, -1*77.00253055555555]}
            icon={markerIcon}
            // icon={directionIcon}
            // rotationAngle={direction} // set the rotation angle
            >

            <Popup>
            <img src={require("https://huggingface.co/front/assets/huggingface_logo-noborder.svg")} alt={'image desc'} style={{ maxWidth: '50px'}} />

            </Popup>

          </Marker> */}
          
    </MapContainer>
  </div>
  )
}
