import React, { useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import * as THREE from 'three'

function ImagePlane({ imageUrl, boxWidth = 6, boxHeight = 6 }) {
  const texture = useLoader(THREE.TextureLoader, imageUrl)
  const meshRef = useRef()
  const { viewport } = useThree()

  const imageAspect = texture.image.width / texture.image.height
  const boxAspect = boxWidth / boxHeight

  let scaleX = 1
  let scaleY = 1

  if (imageAspect > boxAspect) {
    // Image is wider relative to box
    scaleX = boxWidth
    scaleY = boxWidth / imageAspect
  } else {
    // Image is taller relative to box
    scaleY = boxHeight
    scaleX = boxHeight * imageAspect
  }

  return (
    <mesh ref={meshRef} scale={[scaleX, scaleY, 1]}>
      <planeGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} toneMapped={false} />
    </mesh>
  )
}

export const UI_Grid_Item_Img_Layer_ThreeJSFiber_3 = ({ imageUrl }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Canvas orthographic camera={{ zoom: 100, position: [0, 0, 10] }}>
        <ImagePlane imageUrl={imageUrl} />
      </Canvas>
    </div>
  )
}