import {useState} from 'react';

import {useTheme, Box, TextField, InputAdornment} from "@mui/material"

import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import MicIcon from '@mui/icons-material/Mic';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { Button } from '@mui/material';


export const UI_Grid_Item_Chat = ({value, setValue, response, setResponse, responseGeo, setResponseGeo, setAnchorEl, set_popper_text}) => {

// export const UI_Grid_Item_Chat = () => {

    // const [value, setValue] = useState('')

    // const keyDown = (event) => {
    //    console.log('test complete api')
    //   };
      
    const keyDown = (event) => {
        if (event.key === 'Enter') {
          // Prevent the default newline behavior
          event.preventDefault();
          // Call your function here
        //   keyDownTextField();
        }
      };

    const keyDownTextField = async () => {
        const apiKey = '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'; // Replace with your API key
        const url = 'https://api.openai.com/v1/chat/completions';

        const data = {
        model: 'gpt-4o', // or any other OpenAI model
        messages:  [{ role: 'system', content: 'You are a helpful assistant.' },
        { role: 'user', content: value }],
        max_tokens: 1000,
        temperature: 0,
        top_p: 1,
        };

        try {
            const result = await fetch(url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
                },
                body: JSON.stringify(data),
            });

            const jsonResult = await result.json();
            // setResponse(JSON.stringify(jsonResult));
            setResponse(jsonResult['choices'][0]['message']['content']);
            // const formattedJson = JSON.stringify(jsonResult['choices'][0]['message']['content'], null, 4);  // 4 spaces indentation
            // setResponse(formattedJson)

            console.log(response)
        } catch (error) {
            // console.error('Error calling OpenAI API:', error);
            console.log('Error calling OpenAI API:', error);
            // what is type of, error
            setResponse('System Error')
            }
        
        }
    

    

    

//   const fetchImageList = () => {
//     // fetch('http://'+address_backend+'/data/image-list-return/', {

//     fetch('http://localhost:8000/data/image-list-return/',{
//     // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-list-return/', {
//     // fetch('http://172.18.0.2:8000/data/image-list-return/',{
//         method: 'GET',
//   })
//       .then(response => response.json())
//       .then(data => {
//         setImageList(data.images);
//         setShowMap(true);
//       })
//       .catch(error => {
//         console.error('Error fetching image list:', error);
//         setShowMap(false);
//       });
//   };


    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file){

    //console.log('file:', typeof(file))
    // setSelectedFile(file);
    // handleUpload(file);
    // console.log('DONE')

    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl)
      }


    //   handleUpload(selectedImage);
      handleUpload(file);


    };


    const handleUpload = (file) => {
        console.log('handle upload')
        // if (selectedFile) {
        if (file) {
                // console.log('selectedFile' + toString(selectedFile))

          const formData = new FormData();

          // formData.append('file_in', selectedFile);

          // formData.append('file_in', file);

          // handle spaces in name
          const sanitizedFileName = file.name.replace(/\s+/g, '_'); // Replace spaces with underscores
          formData.append('file_in', file, sanitizedFileName); // Append file with the sanitized name

    
        //   fetch('http://'+address_backend+'/data/image-upload-single/', {
    
        // fetch('http://localhost:8000/data/image-upload-single/', {
        fetch('http://www.orbnode.com:8000/data/image-upload-single/', {
                // fetch('http://3.90.25.43:8000/data/image-upload-single/', {
                // fetch('http://ec2-54-92-247-114.compute-1.amazonaws.com:8000/data/image-upload-single/', {
          // fetch('http://172.18.0.2:8000/data/image-upload-single/', {
              method: 'POST',
            body: formData,
          })
            .then(response => response.text())
            .then(data => {
                console.log('************************')
                console.log(data);
                console.log('---------------------')

            })
            // .then(fetchImageList())
            .catch(error => {
              console.error('Error uploading image:', error);
            });


            // setSelectedFile(null)
        }
      };





      const setFocus = (event) =>{
        setAnchorEl(event.currentTarget)

        // const inputBox = event.currentTarget.querySelector(".NAV-BOTTOM");
        // if (inputBox) {
        //   setAnchorEl(inputBox); // Set the Box element as anchorEl
        // }

      }
      
      const setBlur = () => {
        setTimeout(() => setAnchorEl(null), 150); // Delay to allow option selection
      };




      const theme = useTheme();
      const color_theme = theme.palette.mode === "dark" 
      ? theme.palette.grey[900]  // Dark mode background
      : theme.palette.grey[100]; // Light mode background    


    return(
        
        <TextField


        inputProps={{
            // Limit the input to n characters
            maxLength: 190
        }}

        // when page loads text field is the selected component where the cursor begins
        // autoFocus 

        onFocus={setFocus}
        onBlur={setBlur}


        sx={{
            // position: 'fixed',
            // bottom: 0,
            // left: 0,
            // right: 0,

            // width:'60vh',

            pointerEvents:  'auto',

            '& .MuiInputBase-root':{
                borderRadius:6,
                // borderTopLeftRadius: 0, 
                // borderTopRightRadius: 0, 

                ...(response ? { // Conditionally apply top border radius when response is populated
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0
                } : {}),
                
                // boxShadow: '10',
                // boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)', // Add drop shadow

                // backgroundColor: 'rgba(0,0,0, 0.2)',
                // backgroundColor: 'transparent',
                backgroundColor: color_theme,
                // backgroundColor: 'rgba(68, 66, 66)',

                // padding: '1em',

                // padding: '4px 8px', // Adjusting padding to reduce height
                padding:'.25em',
                color: 'white', // Change the text color if needed
            },

            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    // NEED TRANSPARENT - border will always be outlined when cursor is selected OUTSIDE of TEXTFIELD
                    borderColor: 'transparent', // Default border color
                    // borderColor: 'gray', // Default border color
                    // borderWidth: '.1em', // You can adjust the border size here
                    
                    // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Add drop shadow
                    boxShadow: '0px 0px 6px rgba(56, 58, 68)', // Add drop shadow
                },
            '&.Mui-focused fieldset': {
                // NEED TRANSPARENT - border will always be outlined when cursor is selected INSIDE of TEXTFIELD, cursor OUTSIDE TEXTFIELD
                borderColor: 'transparent', // Border color when focused
                // borderColor: 'gray', // Default border color
                // borderWidth: '.1em', // You can adjust the border size here

                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Add drop shadow
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)', // Add drop shadow
            },
            '&:hover fieldset': {
                // NEED TRANSPARENT - border will always be outlined when cursor is selected INSIDE of TEXTFIELD, cursor INSIDE TEXTFIELD
                borderColor: 'transparent', // Border color when hovered
                // borderColor: 'gray', // Default border color
                // borderWidth: '.1em', // You can adjust the border size here

                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Add drop shadow
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)', // Add drop shadow
            },
            },
            '& .MuiInputLabel-root': {
            color: 'black', // Label color
            '&.Mui-focused': {
                color: 'white', // Label color when focused
            },
            },
            '& .MuiInputBase-input': {
            color: 'white', // Input text color
            },

        }}


        // onInput={
        //     (e)=>{
        //         // const textarea = e.target;
        //         // for typescript
        //         const textarea = e.target;
        //         textarea.style.height='auto';
        //         // control based on text amount
        //         // textarea.style.height = `${textarea.scrollHeight}px`;
        //         // also limit to max size of 16 etc.
        //         textarea.style.height = `${Math.min(textarea.scrollHeight, 10 * textarea.clientHeight)}px`;
                
        //         // scrollheight is the total text adding to the height
        //         // clientheight is a predetermined height per textfield
        //         console.log(textarea.scrollHeight)
        //         console.log(textarea.clientHeight)
        //         console.log(3 * textarea.clientHeight)
        //     }
        // }

        // DEPRICATED
        // onKeyPress={keyDownTextField}

        // MUST use event.preventDefault() in function to override default newline behavior
        onKeyDown={keyDown}
        // onKeyDown={(i)=>{
        //     keyDownTextField()
        // }}


        multiline

        // minRows={1}
        maxRows={4}
        // rows={1}

        fullWidth 
        // label={'search for something to do...'}

        // value={JSON.stringify(value)}
        value={value}

        onChange={(i)=>{
            setValue(i.target.value)
            set_popper_text(i.target.value)
            }
        }

        InputProps={{

            startAdornment: (
            <InputAdornment position="start">
                {/* Add your search icon here
                <svg>...</svg> */}

                {/* <img src={logo} width={'40px'}/> */}
                {/* <img src={'https://www.alexdelapaz.com/images/id.jpg'} width={'40px'}/> */}
                {/* <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdKCgSY1O9svcENbY9rD0veMH87OZ9jNj7w&s'} width={'40px'}/> */}
                {/* import logo from '../images/orbnode_logo.png' */}
                <img src={require('../images/orbnode_logo.png')} width={'40em'}/>

      


            </InputAdornment>
            ),




            endAdornment: (
                <>


{/* {selectedImage && <img src={selectedImage} alt="Preview" style={{ width: '200px', height: 'auto', borderRadius: '8px' }} />} */}



                <IconButton
                variant="outlined" color='primary' component="span"

                // onClick={keyDownTextField}
                >
                {/* or onClick={() => callFunc(propValue)} */}
                <ArrowUpwardIcon />
                </IconButton>

|
                <InputAdornment position="end">
                    <input
                    // ensures that only images can be selected
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-file"
                    type="file"
                    //*
                    onChange={handleFileChange}

                    // suggests to the browser that the camera should be used for capturing images, especially on mobile devices
                    // capture="environment"
                    
                    />
                    <label htmlFor="upload-file">
                        <IconButton
                        // onClick={handleUpload}
                        variant="outlined" color='primary' component="span"
                        >
                        
                        <AddAPhotoIcon/>
                        {/* <MicIcon/> */}

                        </IconButton>
                    </label>
                </InputAdornment>

|

                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-file"
                    type="file"

                    //**
                    onChange={handleFileChange}

                    capture="environment" // This attribute is to suggest devices to use camera
                    />
                    <label htmlFor="upload-file">
                    {/* <label> */}
                        <IconButton variant="outlined" color='primary' component="span">
                        
                        <MicIcon/>

                        </IconButton>
                    </label>
                </>
            ),

            
          }}

        
        />
    )
}